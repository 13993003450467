/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import moment from "moment";
import { last, map } from "lodash";
import { toast } from "react-toastify";

import Swal from "sweetalert2";

import {
  GET_SPECIALITY_BY_DOCTOR,
  GET_SPECIALITY,
  GET_SUB_SPECIALITY,
  GET_CONSULTNG_ROOM,
  GET_SCHEDULERS,
  DELETE_SCHEDULERS,
  DELETE_ALL_SCHEDULERS,
  GET_HEALTH_ENTITIES,
  GET_AGREEMENTS,
  GET_HOLIDAYS,
  GET_CERTIFICATIONS,
  GET_APPOINTMENTS,
  GET_ANNOTATIONS,
  GET_DOCTOR,
  GET_CIE,
  GET_CUP,
  GET_CIEXMEDICO,
  GET_CUPXMEDICO,
  GET_ESPXMEDICO_BUSQUEDA,
  GET_DOCTOR_PERMISSION,
  GET_USER_AGREEMENT_BUSQUEDA,
  GET_USER_AGREEMENT,
  GET_DOCTOR_CONSULTING_ROOM,
  GET_CITAS_EN_ESPERA,
  GET_PENDING_APPOINTMENTS,
  GET_CITAS_EN_OFERTA,
  GET_ALL,
  GET_QUERY_INDEX,
  GET_CITAS_SIN_PAGO,
  GET_TARIFA_PRODUCTOS,
  GET_PRODUCTOS_SERVICIOS,
  GET_DETALLE_CARRO_COMPRAS,
  GET_DATOS_COMPRA,
  GET_REPORTE_1,
  SET_ACTUALIZAR_PERFIL_DOCTOR,
  GET_CONSULTORIOS_POR_CITA_ESPERA,
  GET_REDIRECCION_X_PAGO,
  GET_VALIDACION_INGRESO_CARRO,
  GET_CITAS_PACIENTE_ACTIVAS,
  GET_DATOS_VIDEO,
  GET_CITAS_PROXIMAS_DOCTOR,
  GET_CANCELAR_CITA,
  GET_REPROGRAMAR_CITA,
  GET_CITAS_MEDICO_ACTIVAS,
  GET_PETICION_REPROGRAMAR_CITA,
  GET_LLAMAR_HUB_PRUEBA,
  GET_ANTHROPOMETRIC_MEASURES,
  GET_ANTHROPOMETRICS_DOCTOR,
  CREATE_ANTHROPOMETRIC_DOCTOR,
  DELETE_ANTHROPOMETRIC_DOCTOR,
  GET_DIAGNOSES_CIE,
  GET_DIAGNOSES_DOCTOR,
  CREATE_DIAGNOSES_DOCTOR,
  DELETE_DIAGNOSES_DOCTOR,
  GET_DIAGNOSES_CUPS,
  GET_DIAGNOSES_CUPS_DOCTOR,
  CREATE_DIAGNOSES_CUPS_DOCTOR,
  DELETE_DIAGNOSES_CUPS_DOCTOR,
  GET_TEMPLATES_DOCTOR,
  GET_HISTORY_SECTIONS,
  UPDATE_TEMPLATE_DOCTOR,
  GET_CONSULTATION_TYPES,
  CREATE_TEMPLATE_DOCTOR,
  DELETE_TEMPLATE_DOCTOR,
  SET_EXECUTING_REQUEST,
  SET_LAST_ACTION,
  SET_CONSULTATION_PATIENT,
  GET_VITAL_SIGNS,
  GET_VITAL_SIGNS_DOCTOR,
  CREATE_VITAL_SIGN_DOCTOR,
  DELETE_VITAL_SIGN_DOCTOR,
  GET_MEDICINES,
  GET_MEDICINES_DOCTOR,
  CREATE_MEDICINES_DOCTOR,
  DELETE_MEDICINES_DOCTOR,
  SET_PATIENT_EXAMS,
  SET_MEDICAL_SPECIALITIES,
  SET_SPECIALIZED_EXAMS,
  SET_SEARCH_LISTS,
  SET_DATA_TYPES,
  SET_VARIABLE_MEASURES,
  GET_PRODUCT_RATES_BY_DOCTOR,
  GET_SOCIAL_NETWORKS,
} from "./types";

// Utils
import axios from "../../Utils/Http";
import { logout } from "./authAction";
import { urlNewApiDoctors } from "../../enviroment";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file Http.js
 * @description Request handler
 */

const urlApi = require("../../enviroment").api;
const urlApiLaravel = require("../../enviroment").apiLaravel;

export function getProductRatesByDoctor(resource, params) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, params).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PRODUCT_RATES_BY_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetSpecialityByDoctor(resource, especialityByDoctor) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, especialityByDoctor)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_SPECIALITY_BY_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetDoctorPermission(resource, doctorPermission) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, doctorPermission).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DOCTOR_PERMISSION,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

// export function GetSpeciality(resource, especiality) {
//   return (dispatch) => {
//     return axios.post(`${urlApi}${resource}`, especiality).then((res) => {
//       if (res.data.code === 0) {
//         dispatch({
//           type: GET_SPECIALITY,
//           payload: res.data,
//         });
//       }
//       return res.data;
//     });
//   };
// }

export function GetSpeciality() {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/doctor-specialties-subspecialties`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_SPECIALITY,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export async function GetSpecialityNotRedux() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/doctor-specialties-subspecialties`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se encontraron especialidades",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function checkTokenMedicalHistory(token, patientId, citaId) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `https://apidev.doctorhoy.com/check/${token}/medical-history?patient_id=${patientId}&patient_appointment_id=${citaId}`
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          return res.data.url;
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "No fue posible validar el token para historia medica",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      });

    return response;
  };
}

export function getSocialIcons() {
  const response = axios
    .get(`${urlApiLaravel}api/v1/social-networks/`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
}

export function GetAll(resource, action, search, city) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/doctor-search-engine/doctors`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_ALL,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export async function NewGetAll() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/doctor-search-engine/doctors`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se encontraron medicos",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export const transformData = (_data) => {
  const _scheduler_ids = [];
  const t = _data;
  const result = [];
  let priceFinal = 0;

  t.forEach(({ doctor, scheduler, consulting_rooms }) => {
    let _scheduler = [];
    _scheduler_ids.push({ doctor: doctor.id });

    const newArray = [];
    scheduler.forEach(
      ({
        config_calendar: config,
        free_slot_calendar: calendar,
        id: schedulerId,
        consulting_room: room,
      }) => {
        // idsArray.push(schedulerId);
        _scheduler_ids.map((object) => {
          if (object.doctor === doctor.id) {
            var hour = "";
            if (config.start_hour < "12:00:00") {
              hour = "AM";
            } else {
              hour = "PM";
            }
            newArray.push({
              id_calendario: schedulerId,
              hour: hour,
              config_cal: config.consulting_type,
            });
            object.ids_calendario = newArray;
          }
        });

        const returnData = {
          consulting_type: config.consulting_type[0].label,
          consulting_type_value: config.consulting_type[0].value,
          price: config.consulting_type[0].price,
          schedulerId: schedulerId,
          scheduler: calendar,
          room: room,
          id_medico: doctor.user.id,
        };

        _scheduler.push(returnData);
        priceFinal = config.consulting_type[0].price;
      }
    );

    const tf = {
      doctor: {
        id_search: doctor.id_user_doctor,
        speciality:
          doctor.speciality_by_doctor.length > 0
            ? doctor.speciality_by_doctor[0].specialty
            : "",
        subSpeciality:
          doctor.speciality_by_doctor.length > 0
            ? doctor?.speciality_by_doctor[0]?.subspecialty
            : "",
        academy: doctor.academic,
        review: doctor.review,
        teaching: doctor.teaching,
        user: doctor.user.full_name,
        consultoriosCreados: consulting_rooms,
        id_medico: doctor.user.id,
        // direccion: consultorios[0].consultorio.direccion,
        photo: doctor.photo,
      },
      scheduler: _scheduler,
      price: priceFinal,
    };
    result.push(tf);
  });
  result.map((object) => {
    _scheduler_ids.map((element) => {
      if (
        object.doctor.id_search === element.doctor &&
        element &&
        element.ids_calendario
      ) {
        const newArray = [];
        const arrayVideoCallsIds = [];
        const arrayHomeCare = [];
        element.ids_calendario.map((id) => {
          object.scheduler.map((calendar) => {
            if (calendar.consulting_type_value === "0") {
              if (id.config_cal[0].value === "0") {
                newArray.push(id);
              }
              calendar.schedulerId = newArray;
            }
            if (calendar.consulting_type_value === "1") {
              if (id.config_cal[0].value === "1") {
                arrayVideoCallsIds.push(id);
              }
              calendar.schedulerId = arrayVideoCallsIds;
            }
            if (calendar.consulting_type_value === "2") {
              if (id.config_cal[0].value === "2") {
                arrayHomeCare.push(id);
              }
              calendar.schedulerId = arrayHomeCare;
            }
          });
        });
        object.scheduler.schedulerId = element.ids_calendario;
        // object.scheduler[0].schedulerId = element.ids_calendario;
      }
    });
  });
  if (result?.length > 0) {
    const resultSorted = result.sort(
      (a, b) => b.scheduler.length - a.scheduler.length
    );
    return resultSorted;
  } else {
    return result;
  }
};

export const transformDataExamsProcedures = (_data) => {
  // console.log("_data", _data);
  const _scheduler_ids = [];
  const t = _data;
  const result = [];
  let priceFinal = 0;

  t.forEach(({ doctor, scheduler, consulting_rooms }) => {
    let _scheduler = [];
    _scheduler_ids.push({ doctor: doctor.id });

    const newRoom = consulting_rooms.map((room) => {
      let newObj = {
        id: null,
        direccion: null,
        ciudadId: null,
        consultorio: null,
        idMedico: null,
        nombre: null,
      };
      newObj.id = room.id;
      newObj.direccion = room.address;
      newObj.ciudadId = room.city_id;
      newObj.consultorio = room.consulting_room;
      newObj.idMedico = room.doctor_id;
      newObj.nombre = room.doctor_name;

      return newObj;
    });

    const newArray = [];
    scheduler.forEach(
      ({
        config_calendar: config,
        free_slot_calendar: calendar,
        id: schedulerId,
        consulting_room: room,
        technical_cup: cup,
      }) => {
        // idsArray.push(schedulerId);
        _scheduler_ids.map((object) => {
          if (object.doctor === doctor.id) {
            var hour = "";
            if (config.start_hour < "12:00:00") {
              hour = "AM";
            } else {
              hour = "PM";
            }
            newArray.push({
              id_calendario: schedulerId,
              hour: hour,
              config_cal: config.consulting_type,
            });
            object.ids_calendario = newArray;
          }
        });

        const returnData = {
          consulting_type: config.consulting_type[0].label,
          consulting_type_value: config.consulting_type[0].value,
          price: cup.fee,
          exams: true,
          cup_info: cup,
          schedulerId: schedulerId,
          scheduler: calendar,
          room: room,
          id_medico: doctor.user.id,
        };

        _scheduler.push(returnData);
        priceFinal = cup.fee;
      }
    );

    const tf = {
      doctor: {
        id_search: doctor.doctor_id,
        speciality:
          doctor.speciality_by_doctor.length > 0
            ? doctor.speciality_by_doctor[0].specialty
            : "",
        subSpeciality:
          doctor.speciality_by_doctor.length > 0
            ? doctor?.speciality_by_doctor[0]?.subspecialty
            : "",
        academy: doctor.academic,
        review: doctor.review,
        teaching: doctor.teaching,
        user: doctor.user.full_name,
        consultoriosCreados: newRoom,
        id_medico: doctor.user.id,
        // direccion: consultorios[0].consultorio.direccion,
        photo: doctor.photo,
      },
      scheduler: _scheduler,
      price: priceFinal,
    };
    result.push(tf);
    // console.log(newRoom);
  });
  result.map((object) => {
    _scheduler_ids.map((element) => {
      if (object.doctor.id_search === element.doctor && element) {
        const newArray = [];
        const arrayVideoCallsIds = [];
        const arrayHomeCare = [];
        element.ids_calendario.map((id) => {
          object.scheduler.map((calendar) => {
            if (calendar.consulting_type_value === "0") {
              if (id.config_cal[0].value === "0") {
                newArray.push(id);
              }
              calendar.schedulerId = newArray;
            }
            if (calendar.consulting_type_value === "1") {
              if (id.config_cal[0].value === "1") {
                arrayVideoCallsIds.push(id);
              }
              calendar.schedulerId = arrayVideoCallsIds;
            }
            if (calendar.consulting_type_value === "2") {
              if (id.config_cal[0].value === "2") {
                arrayHomeCare.push(id);
              }
              calendar.schedulerId = arrayHomeCare;
            }
          });
        });
        object.scheduler.schedulerId = element.ids_calendario;
        // object.scheduler[0].schedulerId = element.ids_calendario;
      }
    });
  });
  if (result?.length > 0) {
    const resultSorted = result.sort(
      (a, b) => b.scheduler.length - a.scheduler.length
    );
    return resultSorted;
  } else {
    return result;
  }
};

export function GetQueryIndex(resource, id, search, option, idCity) {
  let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
  return (dispatch) => {
    return axios
      .get(`${urlApi}${resource}/${id}/${search}/${option}/${idCity}/10/0`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_QUERY_INDEX,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

// export function GetQueryIndex(resource, id, search, option, idCity) {
//   return new Promise((resolve, reject) => {
//     axios
//     // get_query_doctor/{id}/{search}/{option}/{idCity}
//     //.get(`${urlApi}${resource}/${id}/${search}/${option}/${idCity}`)
//     .get(`${urlApi}${resource}/${id}/${search}/${option}/${idCity}/10/0`)
//       .then((obj) => {
//         resolve(obj.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function GetQueryIndexPromise(resource, id, search, option, idCity) {
  return new Promise((resolve, reject) => {
    axios
      // get_query_doctor/{id}/{search}/{option}/{idCity}
      //.get(`${urlApi}${resource}/${id}/${search}/${option}/${idCity}`)
      .get(`${urlApi}${resource}/${id}/${search}/${option}/${idCity}/10/0`)
      .then((obj) => {
        resolve(obj.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetQueryDoctor(resource, id, option, attentionType) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${urlApi}${resource}/${id}/${option}/${attentionType}`)
      .then((obj) => {
        resolve(obj.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetSubSpeciality(resource, subSpeciality) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, subSpeciality).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_SUB_SPECIALITY,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetConsultingRoom(resource, consultingRoom) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, consultingRoom).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CONSULTNG_ROOM,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getConsultingRoomLaravel(idDoctor) {
  return (dispatch) => {
    return axios
      .get(`${urlNewApiDoctors}api/v1/consulting-rooms/doctor/${idDoctor}`)
      .then((res) => {
        return res;
      })
      .catch(() =>
        Swal.fire({
          title: "No fue posible cargar los consultorios",
          icon: "info",
        })
      );
  };
}

export async function editConsultingRoomNEST(id_consultorio, data) {
  return await axios
    .patch(`${urlNewApiDoctors}api/v1/consulting-rooms/${id_consultorio}`, data)
    .then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "Cambios guardados",
          icon: "success",
        });
      }
      return res;
    })
    .catch(() =>
      Swal.fire({
        title: "No fue posible editar el consultorio",
        icon: "info",
      })
    );
}

export async function createConsultingRoomNEST(data) {
  return await axios
    .post(`${urlNewApiDoctors}api/v1/consulting-rooms`, data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "Consultorio creado",
          icon: "success",
        });
      }
      return res;
    })
    .catch(() =>
      Swal.fire({
        title: "No fue posible crear el consultorio",
        icon: "info",
      })
    );
}
export async function deleteConsultingRoomNEST(id_consultorio) {
  return await axios
    .delete(`${urlNewApiDoctors}api/v1/consulting-rooms/${id_consultorio}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          title: "Confirmado",
          icon: "success",
        });
      }
      return res;
    })
    .catch(() =>
      Swal.fire({
        title: "No fue posible cambiar el estado del consultorio",
        icon: "info",
      })
    );
}

export function GetSchedulers(resource, shedulers) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, shedulers).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_SCHEDULERS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetSchedulersLaravel(doctor, type) {
  return (dispatch) => {
    return axios
      .get(
        `${urlApiLaravel}api/v1/medical-schedule/${doctor}/doctor/${type}/type`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_SCHEDULERS,
            payload: res.data.data,
          });
        }
        return res.data.data[0]?.scheduler;
      });
  };
}

export function DeleteSchedule(resource, shedulers) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, shedulers).then((res) => {
      if (res.data.code === 0) {
        // dispatch({
        //   type: DELETE_SCHEDULERS,
        //   payload: res.data,
        // });
      }
      return res.data;
    });
  };
}
export function DeleteAllSchedule(resource, shedulers) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, shedulers).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_ALL_SCHEDULERS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetHealthEntities(resource, healthEntity) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, healthEntity).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_HEALTH_ENTITIES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetAgreements(resource, agreement) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, agreement).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_AGREEMENTS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetHoliDays(resource, holiDays) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, holiDays).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_HOLIDAYS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCertifications(resource, certification) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, certification).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CERTIFICATIONS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetAppointments(resource, appointment) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, appointment).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_APPOINTMENTS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetAnnotations(resource, annotation) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, annotation).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ANNOTATIONS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

/**
 * Api Laravel
 */
export function setApiDoctorStore(doctor) {
  return (dispatch) => {
    return axios.post(`${urlApiLaravel}api/v1/doctors`, doctor).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DOCTOR,
          payload: res.data,
        });
      }
      if (res.data.code === 1) {
        setTimeout(() => {
          window.location = "login";
        }, 4000);
      }
      return res.data;
    });
  };
}

export function setApiDoctorRegisterPatientWithMedicalAppointment(data) {
  return (dispatch) => {
    return axios
      .post(
        `${urlApiLaravel}api/v1/doctors/register-patient-with-medical-appointment`,
        data
      )
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function setApiExamsProcessDoctorRegisterPatientWithMedicalAppointment(
  data
) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v2/patient-appointments`, data)
      .then((res) => {
        console.log(res);

        return res;
      });
  };
}

export function updateDoctor(doctor) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/doctors/${doctor.id}`, doctor)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR,
            payload: res.data,
          });
        }
        if (res.data.code === 1) {
          Swal.fire({
            title: "Error",
            text: `El siguiente archivo: ${res?.data?.file?.name} excede el limite de carga permitido.`,
            icon: `error`,
          });
        }
        return res.data;
      });
  };
}

export function getPatientAppointments(doctor) {
  return (dispatch) => {
    return axios
      .get(
        `${urlApiLaravel}api/v1/patient-appointments/${doctor}/active/doctor`
      )
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function createMedicalSchedule(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/medical-schedule`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function createMedicalScheduleForExams(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/medical-schedule/new-store`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function createDoctorTechnicalCup(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/doctor-technical-cups`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function getDoctorTechnicalCup(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/doctor-technical-cups/doctor`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data.data;
      });
  };
}

export async function getDoctorExamsConsultationProcedures(medicalSchedule) {
  const response = await axios
    .get(
      `${urlApiLaravel}api/v1/medical-schedule/${medicalSchedule}/exams-consultation-procedures`
    )
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data[0].scheduler;
      }
    })
    .catch((error) => {
      console.log("getDoctorExamsConsultationProcedures ERROR", error);
    });

  return response;
}

export function getDoctorNoSchedulesExamsConsultationProcedures(doctor, id) {
  return (dispatch) => {
    return axios
      .get(
        `${urlApiLaravel}api/v1/medical-schedule/${doctor}/doctor/exams-consultation-procedures/without-schedules?group_cup_id=${id}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
          return res.data.data[0].scheduler;
        }
        return res.data.data;
      });
  };
}

export function updateDoctorTechnicalCup(id, params) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/doctor-technical-cups/${id}`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function deleteDoctorTechnicalCup(id, params) {
  return (dispatch) => {
    return axios
      .delete(`${urlApiLaravel}api/v1/doctor-technical-cups/${id}`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function getCupGroups() {
  return (dispatch) => {
    return axios.get(`${urlApiLaravel}api/v1/cup-groups`).then((res) => {
      if (res.data.code === 0) {
        // dispatch({
        //   type: GET_DOCTOR,
        //   payload: res.data,
        // });
      }
      return res.data.data;
    });
  };
}

export async function getTechnicalCupGroups(doctorId, cupGroupId) {
  const response = await axios
    .get(
      `${urlApiLaravel}api/v1/technical-cup-doctors/cup-group/${doctorId}/${cupGroupId}`
    )
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch((error) => {
      console.log("No se technical cups", error);
    });

  return response;
}

export function updateMedicalSchedule(params) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/medical-schedule/${params.id}`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}
export function updateMedicalScheduleForExamns(id, params) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/medical-schedule/${id}/new-update`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function getApiDoctors(search) {
  return (dispatch) => {
    return axios
      .get(
        `${urlApiLaravel}api/v1/doctors?documentType=${search?.documentType?.trim()}&documentNumber=${search?.document?.trim()}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getApiDoctor(doctor) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/doctors/${doctor.id}/user`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getDoctorCertification(id, certification) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/doctor-certifications/${certification}`)
      .then((res) => {
        return res.data;
      });
  };
}

export function getDoctorCertifications(doctor) {
  return (dispatch) => {
    return axios.get(`${urlApiLaravel}api/v1/certifications`).then((res) => {
      return res.data;
    });
  };
}

export function updateApiDoctorCertification(doctor) {
  return (dispatch) => {
    return axios
      .post(
        `${urlApiLaravel}api/v1/doctors/${doctor.id}/update/certifications`,
        doctor
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetDoctor(resource, doctor) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, doctor).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetDoctorNew(resource, doctor) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/doctors/${doctor.id_user_doctor}/user`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export async function GetDoctorNotRedux(doctor) {
  const response = await axios
    .post(`${urlApi}/Doctor/get_doctor`, doctor)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se encontraro al medico",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function GetCie(resource, cie) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cie).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CIE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function SetDiagnosesCie(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DIAGNOSES_CIE,
      payload: { data },
    });
  };
}

export function GetCup(resource, cup) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cup).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CUP,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCieXMedico(resource, ciexmedico) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, ciexmedico).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CIEXMEDICO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCupXMedico(resource, cupxmedico) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cupxmedico).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CUPXMEDICO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetEspXMedicoBusqueda(resource, espxmedBusqueda) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, espxmedBusqueda).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ESPXMEDICO_BUSQUEDA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetUserAgreementBusqueda(resource, usrAgrBusqueda) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, usrAgrBusqueda).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_USER_AGREEMENT_BUSQUEDA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetUserAgreement(resource, usrAgr) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, usrAgr).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_USER_AGREEMENT,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetConsultingRoomByDoctor(resource, consultorioXMedico) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, consultorioXMedico)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DOCTOR_CONSULTING_ROOM,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetCitaEnEspera(resource, citaEspera) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, citaEspera).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CITAS_EN_ESPERA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCitaEnEsperaLaravel(userDoctor) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `${urlApiLaravel}api/v1/doctors/${userDoctor}/pending-appointments?pending_appointment_type=1`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PENDING_APPOINTMENTS,
            payload: res.data,
          });
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export async function GetCitasEnEsperaInterconsulta(userDoctor) {
  const response = await axios
    .get(
      `${urlApiLaravel}api/v1/patients/${userDoctor}/pending-appointments?pending_appointment_type=2`
    )
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title:
          "Tuvimos inconvenientes al encontrar ofertas a tu solicitud, por favor intenta mas tarde",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export async function GetMedicalInterconsultations(userDoctor) {
  const response = await axios
    .get(
      `${urlApiLaravel}api/v1/medical-interconsultations/${userDoctor}/doctor`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title:
          "No fue posible acceder a tus interconsultas, por favor intenta mas tarde",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export async function GetAllMedicalHistories(patientId, doctorId) {
  const response = await axios
    .get(
      `${urlApiLaravel}api/v1/patient-care/medical-histories/patient/${patientId}/doctor/${doctorId}`
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
}

export function GetCitaEnEsperaLaravelInterconsultas(userDoctor) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `${urlApiLaravel}api/v1/doctors/${userDoctor}/pending-appointments?pending_appointment_type=2`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PENDING_APPOINTMENTS,
            payload: res.data,
          });
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export function GetCitaEnEsperaLaravelAtencionEnCasa(userDoctor) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `${urlApiLaravel}api/v1/doctors/${userDoctor}/pending-appointments?pending_appointment_type=3`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PENDING_APPOINTMENTS,
            payload: res.data,
          });
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export function PendingAppointments(citaEspera) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/pending-appointments`, citaEspera)
      .then((res) => {
        if (res.data.code === 1) {
          Swal.fire({
            title: res.data.message,
          });
        }
        return res.data;
      })
      .catch(() => {
        Swal.fire({
          title: "No se realizo tu busqueda",
          text: "Por favor intenta nuevamente",
          icon: `info`,
        });
      });
  };
}

export async function NewPendingAppointments(citaEspera) {
  const response = await axios
    .post(`${urlApiLaravel}api/v1/pending-appointments`, citaEspera)
    .then((res) => {
      if (res.data.code === 1) {
        Swal.fire(res?.data?.errors[0]);
      }
      if (res.data.code === 0) {
        Swal.fire({
          title: res.data.title,
          text: res.data.message,
          icon: `success`,
        });
        return res.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Ya existe una consulta con estos mismos parametros.",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export async function CupsPendingAppointments(citaEspera) {
  const response = await axios
    .post(`${urlApiLaravel}api/v1/pending-appointments/cups`, citaEspera)
    .then((res) => {
      if (res.data.code === 1) {
        Swal.fire(res?.data?.errors[0]);
      }
      if (res.data.code === 0) {
        Swal.fire({
          title: res.data.title,
          text: res.data.message,
          icon: `success`,
        });
        return res.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Ya existe una consulta con estos mismos parametros.",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function CupsPendingAppointmentsLogin(citaEspera) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/pending-appointments/cups`, citaEspera)
      .then((res) => {
        if (res.data.code === 1) {
          Swal.fire(res?.data?.errors[0]);
        }
        if (res.data.code === 0) {
          Swal.fire({
            title: res.data.title,
            text: res.data.message,
            icon: `success`,
          });
          return res.data;
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ya existe una consulta con estos mismos parametros.",
          icon: `info`,
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };
}

export async function GetCitaEnEsperaNotRedux(resource, citaEspera) {
  const response = await axios
    .post(`${urlApi}${resource}`, citaEspera)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data[0];
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
}

export async function receiveOffers(doctorId) {
  const response = await axios
    .post(`${urlApiLaravel}api/v1/doctors/${doctorId}/update/receive-offers`)
    .then((res) => {
      if (res.data.code === 401) {
        Swal.fire({
          title: "La sesión ha expirado, por favor vuelva a iniciar sesión.",
          icon: `info`,
        });
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        window.location = "login";
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
}

export function NewGetCitaEnEsperaNotRedux(resource, citaEspera) {
  return async (dispatch) => {
    const response = await axios
      .post(`${urlApi}${resource}`, citaEspera)
      .then((res) => {
        if (res.data.code === 0) {
          return res.data.data[0];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export function NewGetCitaEnOferta(idCitaEspera, careType, location) {
  return async (dispatch) => {
    const result = await axios
      .post(
        `${urlApiLaravel}api/v1/offer-appointments/${idCitaEspera}/doctors`,
        { careType, location }
      )
      .then((res) => {
        if (res.data.code === 0) {
          return res.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  };

  // return response;
}
export async function NewGetCitaEnOfertaInterconsultas(
  idCitaEspera,
  careType,
  location
) {
  const result = await axios
    .post(`${urlApiLaravel}api/v1/offer-appointments/${idCitaEspera}/doctors`, {
      careType,
      location,
    })
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return result;
}

export function GetCitaEnOferta(resource, citaOferta) {
  return async (dispatch) => {
    let showError = false;
    let message = "Ya tiene una cita confirmada en este horario.";

    try {
      const res = await axios.post(`${urlApi}${resource}`, citaOferta);

      if (res.data.code !== 0) {
        showError = true;

        if (res.data.code === 3009) {
          message = res.data.message;
        }

        if (res.data.code === 2001) {
          message = res.data.message;
          window.location.reload(false);
        }

        if (res.data.code === 3000) {
          message = res.data.data;
        }
      } else {
        dispatch({
          type: GET_CITAS_EN_OFERTA,
          payload: res.data,
        });

        return res.data;
      }
    } catch (error) {
      showError = true;
    }

    if (showError) {
      Swal.fire({
        title: message,
        icon: `error`,
      });
      return null;
    }
  };
}

export function GetCitasSinPago(resource, citaNoPago) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, citaNoPago).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CITAS_SIN_PAGO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetProductosServicios(resource) {
  //este servicios no necesita parametros

  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PRODUCTOS_SERVICIOS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetTarifaProductos(resource, tarifaProducto) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, tarifaProducto).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_TARIFA_PRODUCTOS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetDetalleCarroCompras(resource, carro) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, carro).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DETALLE_CARRO_COMPRAS,
          payload: res.data,
        });

        let valueDate = last(res.data.data.fecha_inicio);
        if (valueDate) {
          let date = valueDate.substring(0, valueDate.indexOf("T"));
          let hour = valueDate.substring(
            valueDate.indexOf("T") + 1,
            valueDate.length
          );

          localStorage.setItem("date", date);
          localStorage.setItem("hour", hour);
        }
      }
      return res.data;
    });
  };
}

export function GetDetalleOrdenDeComprasLaravel(id) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/purchase-order/${id}/user`)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteOrdenCompraLaravel(id) {
  return (dispatch) => {
    return axios
      .delete(`${urlApiLaravel}api/v1/purchase-order-details/${id}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function GetDatosCompra(resource, datosCompra) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, datosCompra).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DATOS_COMPRA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetReporte1(resource, reporte1) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, reporte1).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_REPORTE_1,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetActualizarPerfilDoctor(resource, doctor) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, doctor).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_ACTUALIZAR_PERFIL_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetConsultoriosPorCitaEspera(resource, consultorioCita) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, consultorioCita).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CONSULTORIOS_POR_CITA_ESPERA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetRedireccionXCitaInmediata(resource, CitaInmediata) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, CitaInmediata).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_REDIRECCION_X_PAGO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetValidacionIngresoCarro(resource) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VALIDACION_INGRESO_CARRO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCitasPacienteActivas(resource, params) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, params).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CITAS_PACIENTE_ACTIVAS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCitasDoctorProximas(resource) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CITAS_PROXIMAS_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetCancelarCita(resource, cita) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cita).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CANCELAR_CITA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetReprogramarCita(resource, cita) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cita).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_REPROGRAMAR_CITA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function ReprogramarCitaLaravel(patientAppointment, cita) {
  return (dispatch) => {
    return axios
      .post(
        `${urlApiLaravel}api/v1/patient-appointments/${patientAppointment}/reschedule`,
        cita
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_REPROGRAMAR_CITA,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetCitasMedico(resource, cita) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cita).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CITAS_MEDICO_ACTIVAS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export async function GetCitasMedicoActivasNew(id) {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/doctors/${id}/patient-appointments/active`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se pudo obtener citas activas",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function GetPeticionReprogramarCita(resource, cita) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cita).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PETICION_REPROGRAMAR_CITA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetLlamarHubPrueba(resource) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_LLAMAR_HUB_PRUEBA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export const setLastAction = (action) => ({
  type: SET_LAST_ACTION,
  value: action,
});

export function saveVideoSummary(params) {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });

    let showError = false;
    try {
      const res = await axios.post(
        `${urlApi}Doctor/registrar_comentario_cita`,
        params
      );
      if (res.data.code !== 0) {
        showError = true;
      } else {
        dispatch({ type: SET_CONSULTATION_PATIENT, value: res.data.data[0] });
        dispatch(setLastAction("summarySaved"));
        Swal.fire({
          title: "Resumen de consulta guardado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    if (showError) {
      Swal.fire({
        title: "Error registrando resumen de la cita",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });
  };
}

export function saveVideoSummaryLaravel(citaId, params) {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });

    let showError = false;
    try {
      const res = await axios.post(
        `${urlApiLaravel}api/v1/patient-care/${citaId}/video-consultation/finished`,
        params
      );
      if (res.data.code !== 0) {
        showError = true;
      } else {
        dispatch({ type: SET_CONSULTATION_PATIENT, value: res.data?.data[0] });
        dispatch(setLastAction("summarySaved"));
        Swal.fire({
          title: "Resumen de consulta guardado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    if (showError) {
      Swal.fire({
        title: "Error registrando resumen de la cita",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });
  };
}

export async function endVideoCallLaravel(citaId) {
  let showError = false;
  try {
    const res = await axios.post(
      `${urlApiLaravel}api/v1/patient-care/${citaId}/video-consultation/finished`
    );
    if (res.data.code !== 0) {
      showError = true;
    } else {
      console.log("Colgada exitosamente");
    }
  } catch (e) {
    showError = true;
  }

  if (showError) {
    console.log("error colgando la llamada");
  }
}

export function GetDatosVideo(resource, cita, fromVideCall) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, cita)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DATOS_VIDEO,
            payload: res.data.data,
          });

          if (fromVideCall) {
            dispatch(setLastAction("datosVideoGotten"));
          }
        }
        return res.data;
      })
      .catch(() => {
        Swal.fire({
          title: "No se pudo obtener información para la video Consulta",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };
}

export const sendPatientMessage = (appointmentId, isDoctor, message) => {
  return async () => {
    const body = {
      appoinment_patient: appointmentId,
      message,
      send_to: isDoctor ? "doctor" : "patient",
    };

    let showError = false;
    try {
      const res = await axios.post(
        `${urlApi}/Doctor/enviar_mensaje_paciente`,
        body
      );
      if (res.data.code !== 0) {
        showError = true;
      }
    } catch (e) {
      showError = true;
    }

    if (showError) {
      // const notifyTo = isDoctor ? 'Paciente' : 'Doctor';
      // return toast.error(`No se pudo notificar al ${notifyTo} que ingrese a la video consulta`, {
      //   position: toast.POSITION.BOTTOM_RIGHT
      // });
    }
  };
};

export const finishAppointment = (appointmentId) => {
  return async () => {
    let showError = false;
    try {
      const res = await axios.post(`${urlApi}/Doctor/finalizar_cita_paciente`, {
        id: appointmentId,
      });
      if (res.data.code !== 0) {
        showError = true;
      }
    } catch (e) {
      showError = true;
    }

    if (showError) {
      Swal.fire({
        title: "Error finalizando la video consulta",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };
};

export function setRating(appointmentId, rating, type) {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const body = {
        id: appointmentId,
        rating,
        rating_type: type,
      };

      const res = await axios.post(`${urlApi}/Doctor/calificar_atencion`, body);
      if (res.data.code !== 0) {
        showError = true;
      } else {
        dispatch(
          setLastAction(
            type === "platformRating"
              ? "platformRatingSuccess"
              : "doctorRatingSuccess"
          )
        );

        Swal.fire({
          title: "Calificación guardada correctamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error en la calificación",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
      return null;
    }
  };
}

export function getDoctorProfile(doctorId) {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const body = {
        action: 6,
        id_user_doctor: doctorId,
      };

      const res = await axios.post(`${urlApi}/Doctor/get_doctor`, body);
      if (res.data.code !== 0) {
        showError = true;
      } else {
        dispatch({
          type: GET_DOCTOR,
          payload: res.data,
        });
      }
    } catch (error) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error obteniendo información del doctor",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });

      return null;
    }

    return {};
  };
}

export function GetAnthropometricMeasures(resource, filter) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}?filter=${filter}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ANTHROPOMETRIC_MEASURES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetAnthropometricXDoctor(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ANTHROPOMETRICS_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateAnthropometricDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_ANTHROPOMETRIC_DOCTOR,
          payload: res.message,
        });

        Swal.fire({
          title: "Medida antropométrica creada correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear la medida antropométrica.",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteAnthropometricDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_ANTHROPOMETRIC_DOCTOR,
          payload: res.message,
        });
        Swal.fire({
          title: "Medida antropométrica eliminada correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar la medida antropométrica",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function GetDiagnosesCie(resource, filter) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}?filter=${filter}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DIAGNOSES_CIE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetDiagnosesCieDoctor(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DIAGNOSES_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateDiagnosesCieDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_DIAGNOSES_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Diagnóstico creado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear el diagnóstico.",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteDiagnosesCieDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_DIAGNOSES_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Diagnostico eliminado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar el diagnostico",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function GetDiagnosesCups(resource, filter, gruopId) {
  return (dispatch) => {
    return axios
      .get(`${urlApi}${resource}?filter=${filter}&group_cup_id=${gruopId}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DIAGNOSES_CUPS,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetTemplatesDoctor(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_TEMPLATES_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function SetDiagnosesCups(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DIAGNOSES_CUPS,
      payload: { data },
    });
  };
}

export function GetVitalSigns(resource, filter) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}?filter=${filter}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VITAL_SIGNS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetDiagnosesCupsDoctor(resource, groupId) {
  return (dispatch) => {
    return axios
      .get(`${urlApi}${resource}?group_cup_id=${groupId}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DIAGNOSES_CUPS_DOCTOR,
            payload: res.data,
          });

          return res.data;
        } else {
          Swal.fire({
            title: "Error al obtener los procedimientos.",
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
  };
}

export function GetHistorySections(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_HISTORY_SECTIONS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateDiagnosesCupsDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_DIAGNOSES_CUPS_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Creado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function GetVitalSignsDoctor(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VITAL_SIGNS_DOCTOR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateTemplateDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_TEMPLATE_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Plantillas creada correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear la plantilla.",
          icon: `warning`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function UpdateTemplateDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: UPDATE_TEMPLATE_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Plantillas actualizada correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al actualizar la plantilla",
          icon: `warning`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function EditTemplateDoctor(templateId, body) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/doctor-templates/${templateId}`, body)
      .then((res) => {
        return res;
      })
      .catch(() => {
        Swal.fire({
          title: "Error al actualizar la plantilla",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };
}

export async function getConsultationTypes() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/consultation-types`)
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Error al obtener tipos de consulta",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}
export async function getPatientTypes() {
  const response = await axios
    .get(`${urlNewApiDoctors}api/v1/patient-types`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Error al obtener tipos de paciente",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}
export async function getEntryPointsToHealthService() {
  const response = await axios
    .get(`${urlNewApiDoctors}api/v1/entry-point-health-services`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Error al obtener puntos de entrada a servicios de salud",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export async function getHealthCareTypes() {
  const response = await axios
    .get(`${urlNewApiDoctors}api/v1/health-care-types`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "Error al obtener tipos de atención medica",
        icon: `info`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function CreateVitalSignDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_VITAL_SIGN_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Signo vital creado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear el signo vital.",
          icon: `warning`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteTemplateDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_TEMPLATE_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Plantilla eliminada correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar la plantilla.",
          icon: `warning`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteDiagnosesCupsDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_DIAGNOSES_CUPS_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Diagnostico eliminado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar el diagnostico",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteVitalSignDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_VITAL_SIGN_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Signo vital eliminado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar el signo vital",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function GetMedicines(resource, filter, distClase) {
  return (dispatch) => {
    return axios
      .get(
        `${urlApi}${resource}?filter=${filter}&distribution_clase_id=${distClase}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_MEDICINES,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function SetMedicines(data) {
  return (dispatch) => {
    dispatch({
      type: GET_MEDICINES,
      payload: { data },
    });
  };
}

export function GetMedicinesDoctor(resource, distClase) {
  return (dispatch) => {
    return axios
      .get(`${urlApi}${resource}?distribution_clase_id=${distClase}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_MEDICINES_DOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function CreateMedicinesDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: CREATE_MEDICINES_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Registro de medicamento creado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al crear el registro de medicamento",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export function DeleteMedicinesDoctor(resource, body) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, body).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: DELETE_MEDICINES_DOCTOR,
          payload: res.data,
        });
        Swal.fire({
          title: "Registro de medicamento eliminado correctamente.",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          title: "Error al eliminar el registro de medicamento",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return res.data;
    });
  };
}

export const save_patient_exam = (body) => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.post(
        `${urlApiLaravel}api/v1/patient-exam`,
        // `${urlApi}/Doctor/create_patient_exam`,
        body
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        Swal.fire({
          title: "Examen guardado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error guardando examenes",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    return "ok";
  };
};

export const update_patient_exam = (body) => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.post(
        `${urlApi}/Doctor/update_patient_exam`,
        body
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        Swal.fire({
          title: "Examen editado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error editando examenes",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    return "ok";
  };
};

export const update_patient_exam_laravel = (body) => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.put(
        `${urlApiLaravel}api/v1/patient-exam/${body?.id}`,
        // `${urlApi}/Doctor/update_patient_exam`,
        body
      );

      if (res.status !== 200) {
        showError = true;
      } else {
        Swal.fire({
          title: "Examen editado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error editando examenes",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    return "ok";
  };
};

export function show_patient_exam(documentId) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/patient-exam/${documentId}`)
      .then((res) => {
        if (res !== null && res.status === 200) {
        }
        return res.data;
      });
  };
}

export function add_explanation_patient_exam(documentId, explanation) {
  return (dispatch) => {
    return axios
      .put(`${urlApiLaravel}api/v1/review-exam-care/${documentId}`, explanation)
      .then((res) => {
        return res;
      })
      .catch(() => {
        Swal.fire({
          title:
            "Tuvimos un inconveniente añadiendo la evaluación, por favor intenta mas tarde",
          icon: `info`,
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };
}

export const get_patient_exams = (patientId, filter, cb) => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(
        `${urlApiLaravel}api/v1/patient-exam?filter=${filter}&patient_id=${patientId}`
        // `${urlApi}/Doctor/get_patient_exams?filter=${filter}&patient_id=${patientId}`
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const exams = res.data.data;
        // Parsing Dates
        map(exams, (eachOne) => {
          eachOne.exam_date_beauty = moment(eachOne.exam_date).format(
            "MMM DD, YYYY"
          );
          eachOne.request_date_beauty = moment(eachOne.request_date).format(
            "MMM DD, YYYY"
          );
          return eachOne;
        });

        dispatch({
          type: SET_PATIENT_EXAMS,
          payload: exams,
        });
        cb();
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo examenes`,
        icon: `error`,
      });
      return;
    }
    cb();
    return "ok";
  };
};

export const delete_patient_exam = (body) => {
  return async () => {
    // dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.post(
        `${urlApi}/Doctor/delete_patient_exam`,
        body
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        Swal.fire({
          title: "Registro eliminado exitosamente",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (e) {
      showError = true;
    }

    // dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: "Error eliminando paciente",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    return "ok";
  };
};

export function createNewConsultingAppoinment(resource, user) {
  return () => {
    return axios.post(`${urlApi}${resource}`, user).then((res) => {
      return res.data;
    });
  };
}

export const get_medical_specialities = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(`${urlApi}/Doctor/get_medical_specialities`);

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const exams = res.data.data;

        dispatch({
          type: SET_MEDICAL_SPECIALITIES,
          payload: exams,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo especialidades`,
        icon: `error`,
      });
      return;
    }

    return "ok";
  };
};

export const get_specialized_exams = (specialityId) => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(
        `${urlApi}/Doctor/get_exams_by_speciality?speciality_id=${specialityId}`
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const exams = res.data.data;

        dispatch({
          type: SET_SPECIALIZED_EXAMS,
          payload: exams,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo especialidades`,
        icon: `error`,
      });
      return;
    }

    return "ok";
  };
};

export const get_variable_measures = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(`${urlApi}/Doctor/get_variable_measures`);

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const measures = res.data.data;

        dispatch({
          type: SET_VARIABLE_MEASURES,
          payload: measures,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo medidas de las variables`,
        icon: `error`,
      });
      return;
    }

    return "ok";
  };
};

export const get_data_types = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(`${urlApi}/Doctor/get_data_types`);

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const types = res.data.data;

        dispatch({
          type: SET_DATA_TYPES,
          payload: types,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo tipos de datos`,
        icon: `error`,
      });
      return;
    }

    return "ok";
  };
};

export const get_search_lists = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST, value: true });
    let showError = false;
    try {
      const res = await axios.get(`${urlApi}/Doctor/get_search_lists`);

      if (res.data.code !== 0) {
        showError = true;
      } else {
        const lists = res.data.data;

        dispatch({
          type: SET_SEARCH_LISTS,
          payload: lists,
        });
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST, value: false });

    if (showError) {
      Swal.fire({
        title: `Error obteniendo listas de busqueda`,
        icon: `error`,
      });
      return;
    }

    return "ok";
  };
};
