/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { toast } from "react-toastify";
import Modal from "react-modal";

import Swal from "sweetalert2";

// Components
import LoginForm from "../../../Components/Forms/Login";

// Redux
import {
  login,
  forgetPassword,
  recoverPasswordLaravel,
  resetPasswordLaravel,
} from "../../../redux/actions/authAction";
import { forgetPasswordUrl, userInfo } from "../../../redux/actions/types";
import {
  getUserInfo,
  loginLaravel,
  getAddressUserLogin,
} from "../../../redux/actions/userAction";
import {
  GetCitaEnEspera,
  PendingAppointments,
  CupsPendingAppointmentsLogin,
} from "../../../redux/actions/DoctorAction";
import { PreloaderDocHoy } from "../../../Utils";

// Assets
import "react-toastify/dist/ReactToastify.css";
import "spinkit/css/spinkit.css";
import logo from "../../../assets/img/logo/logo.png";
import {
  BigContainerLogin,
  ContainerInfoTextLeft,
  ContainerPopUpLogin,
} from "./login.style";

// Config
import { urlHC, siteHost } from "../../../enviroment";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      sendRequest: false,
      recaptcha: true,
      modal: false,
      gotoPrevious: false,
      gotoRedirect: false,
      gotoUrl: "",
      hasSearch: false,
      view: false,
      hasFinderSearch: false,
      showPopUpLogin: true,
      documentRecover: "",
      errorMessage: null,
      showErrorMessage: false,
      resetPassword: false,
      typePassword: "password",
      eye: "fa fa-eye-slash",
      newPassword: "",
      repeatNewPassword: "",
      resetPasswordToken: null,
      showTermsConditions: false,
      newDocumentNumber: "",
      termsAccepted: false,
    };

    this.onRecoveryPassword = this.onRecoveryPassword.bind(this);
    this.handleRecoverPassword = this.handleRecoverPassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleCreatePassword = this.handleCreatePassword.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleShowPopUpLogin = this.handleShowPopUpLogin.bind(this);
    this.onSendFogetPassword = this.onSendFogetPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSendFogetPassword(formData) {
    this.setState({ sendRequest: true });
    // console.log("formData", formData);
    this.props
      .forgetPassword(forgetPasswordUrl, {
        username: formData.user,
        document: formData.document,
      })
      .then((resp) => {
        this.setState({ sendRequest: false });
        this.toggleModal();
        if (resp.code === 1000)
          return Swal.fire({
            title:
              "Lo sentimos, pero no hemos encontrado un usuario con el email ingresado!",
            icon: `error`,
          });

        if (resp.code === 0) {
          Swal.fire({
            title:
              "Hemos enviado a tu correo el enlace para recuperar tu contraseña!",
            icon: `success`,
            timer: 3000,
            timerProgressBar: true,
          });
          const body = {
            username: "camilo",
            cellphone: "+573003438359",
            msg: " Buenos días, Camilo. Hemos enviado a tu correo el enlace para recuperar tu contraseña!",
          };

          // sendSMS(body, '/send-sms').then(() => {
          //   console.log('mensaje enviado');
          // }).catch((errorMsg) => {
          //   console.log('Message bird Error', errorMsg);
          // });
          return;
        }

        if (resp.code === 3000)
          return Swal.fire({
            title: "Error interno del servidor",
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
      });
  }

  componentDidMount() {
    if (localStorage.token) {
      if (localStorage.profile) {
        switch (localStorage.profile) {
          case "Paciente":
            this.context.router.history.push("/Dashboard");
            break;
          case "Medico":
            this.context.router.history.push("/CitasMedico/MisCitas");
            break;
          default:
            this.context.router.history.push("/Dashboard");
            break;
        }
      }
    }
  }

  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tokenFromFinder = params.get("tokenfinder");
    const searchFinder = params.get("findersearch");
    const recoverPasswordToken = params.get("recovery-password-token");
    const termsConditions = params.get("terms-conditions");

    console.log("10/10");
    if (recoverPasswordToken) {
      this.setState({ resetPassword: true });
      this.setState({ resetPasswordToken: recoverPasswordToken });
    }
    if (termsConditions) {
      this.setState({ showTermsConditions: true });
    }

    if (tokenFromFinder) {
      this.setState({ hasFinderSearch: true });
      if (!searchFinder) {
        localStorage.setItem("token", tokenFromFinder);
        setTimeout(() => {
          this.props
            .getUserInfo(userInfo)
            .then((res) => {
              const userInfo = res.data;
              localStorage.setItem("profile", userInfo?.profile?.profile_name);
              localStorage.setItem("full_name", userInfo?.full_name);
              localStorage.setItem("DHusername", userInfo?.username);
            })
            .then(() => {
              this.setState({ hasFinderSearch: false });
              this.context.router.history.push("/Dashboard");
            });
        }, 1000);
      } else {
        const decodeBase = atob(searchFinder);
        // console.log("decodeBase", decodeBase);
        localStorage.setItem("findersearch", decodeBase);
        localStorage.setItem("token", tokenFromFinder);

        setTimeout(() => {
          this.props.getUserInfo(userInfo).then((res) => {
            const userInfo = res.data;
            localStorage.setItem("profile", userInfo?.profile?.profile_name);
            localStorage.setItem("full_name", userInfo?.full_name);
            localStorage.setItem("DHusername", userInfo?.username);
            localStorage.setItem("userIdFinder", userInfo?.id);
            localStorage.setItem("idUser1", userInfo?.id);

            const findersearch = localStorage.getItem("findersearch");
            const finderObjectSearch = JSON.parse(findersearch);
            console.log("finderObjectSearch", finderObjectSearch);
            if (
              finderObjectSearch &&
              finderObjectSearch.typeSearch &&
              finderObjectSearch.typeSearch == 1
            ) {
              const userIdfinder = localStorage.getItem("userIdFinder");
              // console.log("Finder crear busqueda y enviar a busqueda");
              // console.log("userIdfinder", userIdfinder);

              if (finderObjectSearch?.doctor) {
                this.props
                  .PendingAppointments({
                    patient_id: userIdfinder,
                    type: "",
                    specialty_id: null,
                    care_type: finderObjectSearch?.attention_type?.toString(),
                    doctor_id: finderObjectSearch?.data_doctor?.value,
                    city_id: finderObjectSearch?.city,
                    plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                    address_id: "",
                    user_type: "patient",
                    searchType: finderObjectSearch?.searchType,
                    attention_type: finderObjectSearch?.attention_type,
                  })
                  .then(() => {
                    this.setState({ hasFinderSearch: false });
                    this.context.router.history.push("/Dashboard");
                  });
              } else {
                this.props
                  .PendingAppointments({
                    patient_id: userIdfinder,
                    // type: "specialty",
                    specialty_id: finderObjectSearch.speciality,
                    specialty: finderObjectSearch.speciality,
                    care_type: finderObjectSearch.attention_type,
                    observations: finderObjectSearch.reason,
                    city_id: finderObjectSearch?.city,
                    plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                    address_id: "",
                    user_type: "patient",
                    searchType: finderObjectSearch?.searchType,
                    attention_type: finderObjectSearch?.attention_type,
                  })
                  .then(() => {
                    this.setState({ hasFinderSearch: false });
                    this.context.router.history.push("/Dashboard");
                  });
              }
            }
            if (
              finderObjectSearch &&
              finderObjectSearch.typeSearch &&
              finderObjectSearch.typeSearch == 3
            ) {
              const userIdfinder = localStorage.getItem("userIdFinder");
              if (userIdfinder) {
                this.props.getAddressUserLogin(userIdfinder).then((res) => {
                  const dataAddress = res?.data?.data;
                  console.log(dataAddress);
                  const defaultAddress = dataAddress.filter(
                    (address) => address.default_address === "1"
                  );
                  const dataDefaultAddress = JSON.stringify(defaultAddress[0]);
                  // const decodeBaseAdress = atob(dataDefaultAddress);

                  localStorage.setItem(
                    "defaultUserAddress",
                    dataDefaultAddress
                  );

                  const dataSearch = {
                    city_id: finderObjectSearch?.city,
                    cup_id: finderObjectSearch.cup,
                    patient_id: userIdfinder,
                    address_id: defaultAddress[0]?.id,
                    plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                  };

                  // console.log(dataSearch);

                  this.props
                    .CupsPendingAppointmentsLogin(dataSearch)
                    .then(() => {
                      this.setState({ hasFinderSearch: false });
                      this.context.router.history.push("/Dashboard");
                    });

                  // this.setState({ hasFinderSearch: false });
                  // this.context.router.history.push("/Dashboard");
                  this.setState({ hasFinderSearch: false });
                });
              }

              this.setState({ hasFinderSearch: false });
            }
          });
        }, 1000);
      }
    }

    const isMobile = () => {
      const match = window.matchMedia("(pointer:coarse)");
      if (match && match.matches) {
        this.setState({ view: "Mobile" });
      } else {
        this.setState({ view: "Desktop" });
      }
    };

    isMobile();

    const values = queryString.parse(this.props.location.search);

    if (values.redirect !== undefined) {
      this.setState({ gotoPrevious: true });

      if (values.redirect == 2 && values.gotoUrl !== undefined) {
        this.setState({ gotoUrl: values.gotoUrl, gotoRedirect: true });
      }
    }
    if (values.search !== undefined) {
      this.setState({ hasSearch: true });

      const base64 = values.search;

      const decodeBase = atob(base64);
      // console.log("base64", decodeBase);

      localStorage.setItem("search", decodeBase);
    }
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleShowPopUpLogin() {
    this.setState({
      showPopUpLogin: !this.state.showPopUpLogin,
    });
  }

  handleRecoverPassword() {
    if (this.state.documentRecover === "") {
      this.setState({
        errorMessage: "Por favor digite su numero de documento",
      });
      this.setState({
        showErrorMessage: true,
      });
    } else {
      this.props
        .recoverPasswordLaravel({
          document: this.state.documentRecover,
        })
        .then((res) => {
          console.log(res);
          Swal.fire({
            title: "Te hemos enviado un correo",
            text: "Recuerda verificar tambien en tu bandeja de spam",
            icon: `success`,
          }).then(() => {
            this.setState({
              modal: false,
            });
          });
        });
    }
  }

  handleResetPassword() {
    if (this.state.repeatNewPassword === "") {
      this.setState({
        errorMessage: "Complete los campos vacios",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (this.state.repeatNewPassword !== this.state.newPassword) {
      this.setState({
        errorMessage: "Las contraseñas no coinciden",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (
      this.state.repeatNewPassword === this.state.newPassword &&
      this.state.repeatNewPassword !== ""
    ) {
      this.props
        .resetPasswordLaravel({
          password: this.state.repeatNewPassword,
          token: this.state.resetPasswordToken,
        })
        .then((res) => {
          if (res) {
            Swal.fire({
              title: "Contraseña cambiada con exito",
              icon: `success`,
            }).then(() => {
              window.location.href = siteHost;
            });
          }
        });
    }
  }

  handleCreatePassword() {
    if (this.state.newDocumentNumber === "") {
      this.setState({
        errorMessage: "Complete los campos vacios",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (!this.state.termsAccepted) {
      this.setState({
        errorMessage: "Por favor acepte terminos y condiciones",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (this.state.repeatNewPassword === "") {
      this.setState({
        errorMessage: "Complete los campos vacios",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (this.state.repeatNewPassword !== this.state.newPassword) {
      this.setState({
        errorMessage: "Las contraseñas no coinciden",
      });
      this.setState({
        showErrorMessage: true,
      });
    }
    if (
      this.state.repeatNewPassword === this.state.newPassword &&
      this.state.repeatNewPassword !== "" &&
      this.state.newDocumentNumber !== "" &&
      this.state.termsAccepted
    ) {
      Swal.fire({
        title: "Contraseña creada con exito",
        icon: `success`,
      });
    }
  }

  onRecoveryPassword() {
    this.toggleModal();
  }

  onSubmit(data) {
    // console.log('this.props', this.props);
    data.name = "chrome";
    // this.props.loginLaravel("api/login",data ).then((res) => {
    //   console.log('res', res);
    // });
    // return;
    const search = localStorage.getItem("search");
    this.setState({ sendRequest: true });
    this.props
      .loginLaravel("api/login", data)
      .then((res) => {
        // console.log("entro a peticion login");
        // this.props.login(data, "token").then(() => {
        if (res.access_token) {
          localStorage.setItem("token", res.access_token);

          if (res.type == 1 || res.type == 2) {
            window.location.href = `${urlHC}check/${res.access_token}`;
            return;
          }

          // if (this.props.isAuthenticated) {
          this.props.getUserInfo(userInfo).then((res) => {
            if (!res) {
              this.setState({ sendRequest: false });
              return;
            }

            if (this.state.gotoPrevious) {
              if (this.state.gotoRedirect) {
                this.context.router.history.push(this.state.gotoUrl);
              } else {
                window.history.back();
              }
            } else {
              const userInfo = res.data;
              // console.log(userInfo);
              localStorage.setItem("profile", userInfo?.profile?.profile_name);
              localStorage.setItem("full_name", userInfo?.full_name);
              localStorage.setItem("DHusername", userInfo?.username);
              localStorage.setItem("idUser1", userInfo?.id);
              if (
                userInfo &&
                userInfo.profile &&
                userInfo.profile.profile_name &&
                userInfo.profile.profile_name === "Medico"
              ) {
                if (userInfo.state_create == "PV") {
                  this.context.router.history.push("/Doctor/EditDoctor");
                } else {
                  this.context.router.history.push("/CitasMedico/MisCitas");
                }
                //
              } else if (
                userInfo &&
                userInfo.profile &&
                userInfo.profile.profile_name &&
                userInfo.profile.profile_name === "Paciente"
              ) {
                if (search) {
                  const objectSearch = JSON.parse(search);
                  // console.log("objectSearch", objectSearch);
                  // return;
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch == 1
                  ) {
                    // console.log("crear busqueda y enviar a busqueda");
                    this.props
                      .PendingAppointments({
                        patient_id: this.props.userInfo?.id,
                        // type: "specialty",
                        specialty_id: objectSearch.speciality,
                        care_type: objectSearch.attention_type,
                        observations: objectSearch.reason,
                        city_id: objectSearch?.city,
                        plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                        address_id: "",
                      })
                      .then(() => {
                        this.context.router.history.push(
                          "/citaEspera/Seleccion"
                        );
                      });
                  }
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch === 2
                  ) {
                    let typeConsultSearch = "";
                    const typeSearch = objectSearch.typeSearchProgramada;
                    switch (objectSearch.typeSearchProgramada) {
                      case 1:
                        typeConsultSearch = objectSearch.speciality;
                        break;
                      case 2:
                        typeConsultSearch = objectSearch.cies;
                        break;
                      case 3:
                        typeConsultSearch = objectSearch.doctor;
                        break;
                      default:
                        break;
                    }

                    this.context.router.history.push(
                      `/Buscador/Busqueda/${typeConsultSearch}/${objectSearch.city}/${typeSearch}`
                    );
                  }
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch === 3
                  ) {
                    this.context.router.history.push(
                      `/Buscador/Busqueda/${objectSearch.service}/${objectSearch.city}/${objectSearch.typeSearchProgramada}`
                    );
                  }
                } else {
                  this.context.router.history.push("/Dashboard");
                }
              } else {
                this.context.router.history.push("/Dashboard");
              }
            }
          });
        }

        if (this.props.messageError && this.props.messageError !== "") {
          this.setState({ sendRequest: false });
          return toast.error(this.props.messageError, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((e) => {
        this.setState({ sendRequest: false });
        if (e.response?.status == 401) {
          Swal.fire({
            title: e?.response?.data?.message,
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Error interno",
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        // console.log("aqui error", e.response);
      });
  }

  render() {
    const {
      sendRequest,
      hasFinderSearch,
      resetPassword,
      typePassword,
      showTermsConditions,
    } = this.state;
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
      overlay: {
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      },
    };

    const goBackHome = () => {
      window.location.href = "https://www.doctorhoy.com/";
    };
    if (hasFinderSearch) {
      return (
        <>
          <PreloaderDocHoy />
        </>
      );
    }
    if (resetPassword) {
      return (
        <BigContainerLogin>
          <div className="container-reset-passoword">
            <h3>Restablece tu contraseña</h3>
            <hr />
            <label>
              Nueva contraseña
              <a
                className="cursor-pointer ml-2"
                onClick={() => {
                  if (typePassword == "password") {
                    this.setState({ eye: "fa fa-eye" });
                    this.setState({ typePassword: "text" });
                  } else {
                    this.setState({ eye: "fa fa-eye-slash" });
                    this.setState({ typePassword: "password" });
                  }
                }}
              >
                <i className={this.state.eye} aria-hidden="true"></i>
              </a>
            </label>
            <input
              placeholder="Nueva contraseña"
              type={typePassword}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
            <br />
            <br />
            <label>
              Repite contraseña
              <a
                className="cursor-pointer ml-2"
                onClick={() => {
                  if (typePassword == "password") {
                    this.setState({ eye: "fa fa-eye" });
                    this.setState({ typePassword: "text" });
                  } else {
                    this.setState({ eye: "fa fa-eye-slash" });
                    this.setState({ typePassword: "password" });
                  }
                }}
              >
                <i className={this.state.eye} aria-hidden="true"></i>
              </a>
            </label>
            <input
              placeholder="Repite contraseña"
              type={typePassword}
              onChange={(e) =>
                this.setState({ repeatNewPassword: e.target.value })
              }
            />
            {this.state.showErrorMessage && (
              <span>{this.state.errorMessage}</span>
            )}
            <hr />
            <button onClick={this.handleResetPassword}>Enviar</button>
          </div>
        </BigContainerLogin>
      );
    }

    if (showTermsConditions) {
      return (
        <BigContainerLogin>
          <div className="container-terms-conditions">
            <label>Numero de documento</label>
            <input
              placeholder="Nueva contraseña"
              type="text"
              onChange={(e) =>
                this.setState({ newDocumentNumber: e.target.value })
              }
            />
            <br />
            <br />
            <label>
              Nueva contraseña
              <a
                className="cursor-pointer ml-2"
                onClick={() => {
                  if (typePassword == "password") {
                    this.setState({ eye: "fa fa-eye" });
                    this.setState({ typePassword: "text" });
                  } else {
                    this.setState({ eye: "fa fa-eye-slash" });
                    this.setState({ typePassword: "password" });
                  }
                }}
              >
                <i className={this.state.eye} aria-hidden="true"></i>
              </a>
            </label>
            <input
              placeholder="Nueva contraseña"
              type={typePassword}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
            <br />
            <br />
            <label>
              Repite contraseña
              <a
                className="cursor-pointer ml-2"
                onClick={() => {
                  if (typePassword == "password") {
                    this.setState({ eye: "fa fa-eye" });
                    this.setState({ typePassword: "text" });
                  } else {
                    this.setState({ eye: "fa fa-eye-slash" });
                    this.setState({ typePassword: "password" });
                  }
                }}
              >
                <i className={this.state.eye} aria-hidden="true"></i>
              </a>
            </label>
            <input
              placeholder="Repite contraseña"
              type={typePassword}
              onChange={(e) =>
                this.setState({ repeatNewPassword: e.target.value })
              }
            />
            <hr />
            <div className="checkTerms">
              <a
                href="https://www.doctorhoy.com/terminos-y-condiciones/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aceptar terminos y condiciones
              </a>
              <input
                type="checkbox"
                onChange={(e) =>
                  this.setState({ termsAccepted: e.target.checked })
                }
              />
            </div>
            {this.state.showErrorMessage && (
              <span>{this.state.errorMessage}</span>
            )}
            <hr />
            <button onClick={this.handleCreatePassword}>Enviar</button>
          </div>
        </BigContainerLogin>
      );
    }

    return (
      <BigContainerLogin>
        {this.state.modal && (
          <Modal
            // shouldCloseOnOverlayClick={false}
            isOpen={this.state.modal}
            onRequestClose={this.toggleModal}
            style={customStyles}
          >
            <div>
              <h3>Recupera tu cuenta</h3>
              <hr />
              <span>
                Por favor ingresa tu numero de cedula, a continuación enviaremos
                un <br /> correo electronico a tu cuenta asociada para que
                continues el proceso.
              </span>
              <br />
              <br />
              <input
                style={{ padding: "5px" }}
                placeholder="Documento de identidad"
                onChange={(e) =>
                  this.setState({ documentRecover: e.target.value })
                }
              />
              {this.state.showErrorMessage && (
                <>
                  <br />
                  <span style={{ fontSize: "12px", color: "red" }}>
                    {this.state.errorMessage}
                  </span>
                </>
              )}
              <hr />
              <button
                onClick={this.handleRecoverPassword}
                style={{
                  backgroundColor: "#e07c2c",
                  padding: "5px 15px 5px 15px",
                  color: "#fff",
                  borderRadius: "10px",
                  border: "none",
                }}
              >
                Enviar correo electronico
              </button>
            </div>
          </Modal>
        )}
        <ContainerInfoTextLeft>
          <div>
            <h1>
              Se el protagonista de tu{" "}
              <span style={{ color: "#FF8B0D", margin: "0px", padding: "0px" }}>
                bienestar.
              </span>
            </h1>
            <h3>¡Tu salud en tus manos!. Todo en un solo lugar</h3>
          </div>
          <div>
            <ul>
              <li>Buscador multiopción</li>
              <li>Citas para hoy mismo 7:00am/9:00pm</li>
              <li>Citas programadas para otro día</li>
              <li>Agenda en línea 24/7</li>
              <li>Videoconsulta</li>
              <li>Consulta presencial</li>
              <li>Citas para atención domiciliaria</li>
              <li>Historia clínica en línea</li>
              <li>Agenda exámenes médicos y procedimientos</li>
              <li>Pagos en línea</li>
              <li>Visualización y resultado de exámenes médicos en línea</li>
              <li>Canal exclusivo de telemedicina</li>
              <li>Fórmulas médicas e incapacidades en línea</li>
            </ul>
          </div>
        </ContainerInfoTextLeft>
        <div className="welcome-login-text-container">
          <div className="login-card-container">
            <div className="login-card-subcontainer">
              <div className="container-logo-login">
                <img src={logo} alt="Logo" onClick={goBackHome} />
              </div>
              <LoginForm
                onIraRegistro={() =>
                  this.context.router.history.push("/NewPersonRegister")
                }
                submitForm={this.onSubmit}
                status={sendRequest}
                openModal={this.toggleModal}
              />
            </div>
            <div className="login-card-footer">
              <span>¿Eres usuario Médico?</span>
              <span
                className="text1"
                onClick={() =>
                  this.context.router.history.push("/medicalLogin")
                }
              >
                Ingresa a DoctorHoy Aquí
              </span>
            </div>
            <div className="login-card-footer-text">
              <span>No tenemos convenios con EPS</span>
            </div>
          </div>
        </div>
      </BigContainerLogin>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  messageError: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  getUserInfo: PropTypes.func,
  getAddressUserLogin: PropTypes.func,
  recoverPasswordLaravel: PropTypes.func,
  resetPasswordLaravel: PropTypes.func,
  loginLaravel: PropTypes.func,
  forgetPassword: PropTypes.func,
  className: PropTypes.string,
};

Login.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  messageError: state.auth.messageError,
  isAuthenticated: state.auth.isAuthenticated,
  stateLoading: state.auth.stateLoading,
  forgetPasswordResponse: state.auth.forgetPasswordResponse,
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, {
  login,
  forgetPassword,
  getUserInfo,
  recoverPasswordLaravel,
  resetPasswordLaravel,
  loginLaravel,
  GetCitaEnEspera,
  PendingAppointments,
  CupsPendingAppointmentsLogin,
  getAddressUserLogin,
})(Login);
