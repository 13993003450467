/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Col, Input, FormGroup, Label, Row, Alert } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import Modal from "react-modal";
import { getSocialIcons } from "../../../redux/actions/DoctorAction";
import schema, { parseInfo } from "./formConfig";
import { values } from "core-js/es7/object";

// Assets
import { renderError } from "../../../Utils";
import "spinkit/css/spinkit.css";
import { Fragment } from "react";
import Demo from "./cropper";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file index.js
 * @description Doctor Update Register
 */

function Form({
  submitForm,
  countries,
  onSelectCountry,
  departmentBirth,
  onSelectDepartment,
  cityBirth,
  ethnicGroups,
  gender,
  cityResident,
  onSelectCountryResident,
  departmentResident,
  onSelectDepartmentResident,
  loading,
  pendingCertificates,
  formatRegisterType,
  espxmedicbusqueda,
  options,
  cellSwitch,
  documentTypes,
  onViewCertifications,
  onChangeFileCertification,
  initialValues,
  userInfo,
  newCertifications,
  showCertification,
  socials,
}) {
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    if (data?.academic === "") {
      data.academic = "N/A";
    }
    if (data?.teaching === "") {
      data.teaching = "N/A";
    }
    const parsedData = parseInfo(data);

    submitForm(parsedData);
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemForCropper, setItemForCropper] = useState("");
  const [socialMediaIcons, setSocialMediaIcons] = useState([]);

  const [view, setView] = useState(false);

  function openModal(e, item) {
    isMobile();
    setIsOpen(true);
    setItemForCropper(item);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSocialIcons();
        setSocialMediaIcons(response.data);
      } catch (error) {
        console.error("Error fetching social media icons:", error);
      }
    };

    fetchData();
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  const isMobile = () => {
    const match = window.matchMedia("(pointer:coarse)");
    if (match && match.matches) {
      setView("Mobile");
    } else {
      setView("Desktop");
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: 0,
      maxHeight: "70%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const MobileStyles = {
    content: {
      top: "70%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const viewCertifications = (item) => {
    console.log("view cert", item);
    onViewCertifications(item);
  };

  const printCertifications = (pendingCertificates, certifications) => {
    var div = pendingCertificates.map((item) => {
      let found = false;
      let idCertMed = null;
      if (certifications) {
        for (let cert of certifications) {
          // console.log('cert',cert);
          if (cert.certification_id === item.id) {
            found = true;
            idCertMed = cert.id;
            break;
          }
        }
      }

      return (
        <Col key={`item-${item.id}`} md="4">
          <div className="form-group">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label className="font-file-name" htmlFor={`document-${item.id}`}>
                {item.name}{" "}
                {item.type === "img" ? (
                  <span style={{ color: "orange", fontSize: "12px" }}>
                    (png/jpg)
                  </span>
                ) : (
                  <span style={{ color: "orange", fontSize: "12px" }}>
                    (pdf)
                  </span>
                )}
                {item.required && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    *
                  </span>
                )}
              </label>
              {found ? (
                <a
                  className="btn btn-warning btn-sm mr-1"
                  onClick={() => showCertification(idCertMed)}
                >
                  <i className="fa fa-eye text-white fa-2x"></i>
                </a>
              ) : (
                ""
              )}
            </div>
            {item.type === "img" ? (
              <>
                <input
                  type="file"
                  className="form-control"
                  id={`document-${item.id}`}
                  ref={register}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={(e) => onChangeFileCertification(e, item)}
                />
              </>
            ) : (
              <>
                <input
                  type="file"
                  className="form-control"
                  id={`document-${item.id}`}
                  accept="application/pdf"
                  ref={register}
                  onChange={(e) => onChangeFileCertification(e, item)}
                />
              </>
            )}
          </div>
        </Col>
      );
    });
    return div;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2 className="text-primary font-weight-bold">
            ACTUALIZA TUS DATOS Y CARGAR TUS DOCUMENTOS
          </h2>
        </Col>
        {userInfo && userInfo.state_create == "PV" && (
          <Col md={12}>
            <Alert color="warning">
              Por favor ingresa todos tus datos y documentos solicitados para
              que DoctorHoy verifique sus datos y realice su activación.
            </Alert>
          </Col>
        )}
        <Col md="6">
          <div className="form-group">
            <Label>
              Tipo Documento <span className="text-danger">*</span>
            </Label>
            <Controller
              name="document_type"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={documentTypes || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.document_type
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.document_type && renderError(errors.document_type.message)}
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Label>
              Número Documento <span className="text-danger">*</span>
            </Label>
            <input
              type="text"
              name="document"
              className={`form-control ${errors.document && "is-invalid"}`}
              ref={register}
            />
            {errors.document && renderError(errors.document.message)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <div className="form-group">
            <Label>
              Primer Nombre <span className="text-danger">*</span>
            </Label>
            <input
              type="text"
              name="name"
              className={`form-control ${
                errors && errors.name && "is-invalid"
              }`}
              ref={register}
            />
            {errors.name && renderError(errors.name.message)}
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <Label>Segundo Nombre</Label>
            <input
              type="text"
              name="second_name"
              className="form-control"
              ref={register}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <div className="form-group">
            <Label>
              Primer Apellido <span className="text-danger">*</span>
            </Label>
            <input
              type="text"
              name="last_name"
              className={`form-control ${errors.lastName && "is-invalid"}`}
              ref={register}
            />
            {errors.lastName && renderError(errors.lastName.message)}
          </div>
        </Col>
        <Col md="6" className="text-left">
          <div className="form-group">
            <Label>Segundo Apellido</Label>
            <input
              type="text"
              name="second_last_name"
              className="form-control"
              ref={register}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="text-left">
          <FormGroup>
            <Label htmlFor="professional_card">
              <strong>Tarjeta Profesional</strong>
              <span className="text-danger">*</span>
            </Label>
            <input
              type="text"
              name="professional_card"
              className={`form-control ${
                errors && errors.professional_card && "is-invalid"
              }`}
              ref={register}
            />
            {errors.professional_card &&
              renderError(errors.professional_card.message)}
          </FormGroup>
        </Col>
        <Col md="4" className="text-left">
          <FormGroup>
            <Label htmlFor="simultaneous_appointments">
              <strong>Citas simultáneas</strong>
              {/* <span className="text-danger">*</span> */}
            </Label>
            <input
              type="text"
              name="simultaneous_appointments"
              className={`form-control text-right ${
                errors && errors.simultaneous_appointments && "is-invalid"
              }`}
              defaultValue="1"
              ref={register}
            />
            {errors.simultaneous_appointments &&
              renderError(errors.simultaneous_appointments.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Fecha de Nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <input
              type="date"
              name="birthdate"
              className="form-control"
              ref={register}
            />
            {/* <Controller
              name="birthdate"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Input
                    type="date"
                    name="birthDate"
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            /> */}
            {errors.birthdate && renderError(errors.birthdate.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label>
              Genero <span className="text-danger">*</span>
            </Label>
            <Controller
              name="gender"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={gender || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    isClearable
                    value={value}
                    styles={{
                      control: (provided) =>
                        errors.gender
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.gender && renderError(errors.gender.message)}
          </FormGroup>
        </Col>
        <Col md="3" className="text-left">
          <div className="form-group">
            <Label>
              Correo Electrónico<span className="text-danger">*</span>
            </Label>
            <input
              type="email"
              name="email"
              className="form-control"
              ref={register}
            />
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Label>
              Teléfono medico<span className="text-danger">*</span>
            </Label>
            <Controller
              name="cel_phones"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ onChange, value, ref }) => (
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  placeholder="Teléfono"
                  country="co"
                  onChange={(phone) => onChange(phone)}
                  value={value}
                  inputRef={ref}
                />
              )}
            />
            {errors.cel_phones && renderError(errors.cel_phones.message)}
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Label>
              Teléfono secretaria<span className="text-danger">*</span>
            </Label>
            <Controller
              name="secretary_phone"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ onChange, value, ref }) => (
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  placeholder="Teléfono"
                  country="co"
                  onChange={(phone) => onChange(phone)}
                  value={value}
                  inputRef={ref}
                />
              )}
            />
            {/* {errors.phone && renderError(errors.phone.message)} */}
          </div>
        </Col>
        {/* <Col md="3" className="text-left">
          <div className="form-group">
            <Label>Instagram</Label>
            <input
              type="text"
              name="instagram"
              className="form-control"
              ref={register}
            />
          </div>
        </Col>
        <Col md="3" className="text-left">
          <div className="form-group">
            <Label>Facebook</Label>
            <input
              type="text"
              name="facebook"
              className="form-control"
              ref={register}
            />
          </div>
        </Col> */}
      </Row>
      <Row className="bg-light">
        <Col md="4" className={"mt-3"}>
          <h6>
            <strong>Lugar de Nacimiento</strong>
          </h6>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Pais de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="birth_country"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={countries || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id_country}
                    onChange={(e) => {
                      console.log("Pais de nacimiento seleccionado", e);
                      onChange(e);
                      onSelectCountry(e);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.birth_country
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.birth_country && renderError(errors.birth_country.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Dpto de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="birth_department"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={departmentBirth || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id_department}
                    onChange={(e) => {
                      console.log("Departamento de nacimiento seleccionado", e);
                      onChange(e);
                      onSelectDepartment(e);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.department_birth
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.birth_department &&
              renderError(errors.birth_department.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Ciudad de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="birth_city"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={cityBirth || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id_city}
                    onChange={onChange}
                    isClearable
                    value={value}
                    styles={{
                      control: (provided) =>
                        errors.birth_city
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.birth_city && renderError(errors.birth_city.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col md="4">
          <h6>
            <strong>Lugar de Residencia</strong>
          </h6>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Pais de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="resident_country"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={countries || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id_country}
                    onChange={(e) => {
                      console.log("Pais de Residencia seleccionado", e);
                      onChange(e);
                      onSelectCountryResident(e);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.resident_country
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.resident_country &&
              renderError(errors.resident_country.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Dpto de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="resident_department"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={departmentResident || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id_department}
                    onChange={(e) => {
                      console.log("Departamento de Residencia seleccionado", e);
                      onChange(e);
                      onSelectDepartmentResident(e);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.resident_department
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.resident_department &&
              renderError(errors.resident_department.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Ciudad de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="resident_city"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={cityResident || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    isClearable
                    value={value}
                    styles={{
                      control: (provided) =>
                        errors.resident_city
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.resident_city && renderError(errors.resident_city.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12" className={"mt-4"}>
          <FormGroup>
            <Label>
              <strong>Sitio Web</strong>
            </Label>
            <input
              type="text"
              name="web_site"
              placeholder="https://"
              className="form-control"
              ref={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col md="4" className={"mt-3"}>
          <h6>
            <strong>Redes Sociales</strong>
          </h6>
        </Col>
      </Row>
      <Row className="bg-light">
        {socials && socials.length > 0
          ? socials.map((media) => (
              <Col md="6" key={media.id}>
                <FormGroup>
                  <Label>
                    <strong>{media.name}</strong>
                  </Label>
                  <input
                    id={`SM${media.id}`}
                    type="text"
                    name={`social_network_id${media.id}`}
                    placeholder={`https://www.${media.name.toLowerCase()}.com/`}
                    className="form-control"
                    ref={register}
                    // value={media.url}
                    defaultValue={media.url}
                  />
                </FormGroup>
              </Col>
            ))
          : socialMediaIcons.map((media) => (
              <Col md="6" key={media.id}>
                <FormGroup>
                  <Label>
                    <strong>{media.name}</strong>
                  </Label>
                  <input
                    id={`SM${media.id}`}
                    type="text"
                    name={`social_network_id${media.id}`}
                    placeholder={`https://www.${media.name.toLowerCase()}.com/`}
                    className="form-control"
                    ref={register}
                  />
                </FormGroup>
              </Col>
            ))}
      </Row>

      <Row>
        <Col md="12">
          <FormGroup>
            <Label>
              <strong>Describe tu perfil</strong>
              <span className="text-danger">*</span>
            </Label>
            <textarea
              type="text"
              name="review"
              className={`form-control ${
                errors && errors.resena && "is-invalid"
              }`}
              ref={register}
            />
            {errors.review && renderError(errors.review.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>
              <strong>Logros Académicos</strong>
            </Label>
            <textarea
              type="text"
              name="academic"
              className="form-control"
              defaultValue="N/A"
              ref={register}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              <strong>Docencia</strong>
            </Label>
            <textarea
              type="text"
              name="teaching"
              className="form-control"
              ref={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="bg-light py-3">
        <Col md="12" className="text-center mb-4">
          <h2 className="text-primary font-weight-bold">
            REALIZA LA CARGA DE TUS DOCUMENTOS
          </h2>
        </Col>
      </Row>
      <Row className="bg-light">
        {newCertifications &&
          printCertifications(newCertifications, initialValues.certifications)}
        {modalIsOpen && (
          <div>
            <Modal
              isOpen={modalIsOpen}
              // onRequestClose={closeModal}
              style={view === "Mobile" ? MobileStyles : customStyles}
              ariaHideApp={false}
              contentLabel="Example Modal"
            >
              <Demo
                closeModal={closeModal}
                item={itemForCropper}
                onChangeFileCertification={onChangeFileCertification}
                view={view}
              />
            </Modal>
          </div>
        )}
      </Row>
      <Row hidden>
        <Col md="12 py-3">
          <h6>Seleccione sus especialidades y subespecialidades</h6>
        </Col>
      </Row>
      <Row hidden>
        <Col md="12">
          <BootstrapTable
            data={espxmedicbusqueda}
            version="4"
            striped
            hover
            responsive
            pagination
            search={true}
            options={options}
            searchPlaceholder="Buscar"
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn
              dataField="esp_name"
              dataSort
              tdStyle={{ whiteSpace: "normal" }}
              thStyle={{ whiteSpace: "normal" }}
            >
              Especialidad
            </TableHeaderColumn>
            <TableHeaderColumn
              tdStyle={{ whiteSpace: "normal" }}
              thStyle={{ whiteSpace: "normal" }}
              dataField="subesp_name"
              dataSort
            >
              Subespecialidad
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={formatRegisterType}
              dataSort
              width="20%"
            >
              Tipo
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="active"
              dataFormat={cellSwitch}
              dataSort
              width="15%"
            >
              Estado
            </TableHeaderColumn>
          </BootstrapTable>
        </Col>
      </Row>

      <Row>
        <Col className="text-left pt-3 mb-5" col="12">
          <Button type="submit" color="primary">
            {loading ? (
              <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24" />
            ) : (
              "Actualizar perfil"
            )}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

Form.propTypes = {
  pendingCertificates: PropTypes.array,
  gender: PropTypes.array,
  ethnicGroups: PropTypes.array,
  countries: PropTypes.array,
  parentesco: PropTypes.array,
  submitForm: PropTypes.func,
  departmentBirth: PropTypes.array,
  cityBirth: PropTypes.array,
  departmentResident: PropTypes.array,
  cityResident: PropTypes.array,
  onSelectCountry: PropTypes.func,
  onSelectDepartment: PropTypes.func,
  onSelectCountryResident: PropTypes.func,
  onSelectDepartmentResident: PropTypes.func,
  loading: PropTypes.bool,
  formatRegisterType: PropTypes.array,
  cellSwitch: PropTypes.func,
  onViewCertifications: PropTypes.func,
  onChangeFileCertification: PropTypes.func,
};

export default Form;
