/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import { Button, Col, Input, FormGroup, Label, Row } from "reactstrap";
import AddressModal from "./AddressModal";

// schema
import schema from "./formConfig";

// Utils
import { renderError } from "../../../Utils";
import "react-tagsinput/react-tagsinput.css";
import {
  getCitiesSelect,
  getCountriesRegister,
  getStates,
} from "../../../redux/actions/GenericAction";
import {
  getAddressUser,
  deleteAddressUser,
} from "../../../redux/actions/userAction";

/**
 * @author Intelisoft
 * @file index.js
 * @description Application Footer
 */

function Form({
  state,
  props,
  submitForm,
  // onSelectCountry,
  // onSelectDepartment,
  // onSelectCountryBirth,
  // onSelectDepartmentBirth,
  // onSelectCountryResident,
  // onSelectDepartmentResident,
  loading,
  fileChangedHandler,
  person,
  photo,
}) {
  const { departmentBirth, cityBirth, departmentResident, cityResident } =
    state;
  const {
    gender,
    parentesco,
    ocupacion,
    escolaridad,
    documentTypes,
    ethnicGroups,
    bloodTypes,
    maritalStatus,
    healthEntities,
    agreements,
    plans,
  } = props;

  const [username, setUsername] = useState(null);
  const [imgSrc, setImgSrc] = useState(
    person?.photo
      ? `data:image/png;base64, ${person.photo}`
      : "/assets/img/avatars/fotoUser.png"
  );
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSelected, setStateSelected] = useState(null);
  const [citySelected, setCitySelected] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const [genderSelected, setGenderSelected] = useState({
    id: 1,
    name: "Masculino",
    action: 0,
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [actionModal, setActionModal] = useState(null);
  const [infoEdit, setInfoEdit] = useState(null);
  const [view, setView] = useState(false);
  const [totalUserAddress, setTotalUserAddress] = useState([]);

  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: person,
    // defaultValues: {name:'un nombre', birthdate: 'bir'},
  });

  const handleImage = (event) => {
    const image = URL.createObjectURL(event.target.files[0]);
    setImgSrc(image);
    console.log("change", event.target.files[0]);

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      // console.log(reader.result);
      fileChangedHandler(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  useEffect(() => {
    if (!username && person) {
      setUsername(person.document);
    }
    if (photo) {
      setImgSrc(`data:image/png;base64, ${photo}`);
      fileChangedHandler(`data:image/png;base64, ${photo}`);
    }
    reset(person);
  }, [person, photo]);

  const onSubmit = (data) => {
    data.username = username;
    submitForm(data);
  };

  useEffect(async () => {
    const response = await getAddressUser(person?.id_user);
    setTotalUserAddress(response?.data?.data);
  }, []);

  useEffect(async () => {
    const response = await getCountriesRegister();
    setCountries(response);
  }, []);

  useEffect(async () => {
    setStateSelected(null);
    if (country) {
      const response = await getStates(country.id);
      setStates(response);
    }
  }, [country]);

  useEffect(async () => {
    setCitySelected(null);
    if (stateSelected) {
      const response = await getCitiesSelect(stateSelected.id);
      setCities(response);
    }
  }, [stateSelected]);

  const isMobile = () => {
    const match = window.matchMedia("(pointer:coarse)");
    if (match && match.matches) {
      setView("Mobile");
    } else {
      setView("Desktop");
    }
  };

  const customStyles = {
    content: {
      width: "30%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const MobileStyles = {
    content: {
      top: "70%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function openModal(action, info) {
    isMobile();
    setIsOpen(true);
    setActionModal(action);
    setInfoEdit(info);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2 className="text-primary">ACTUALIZA TUS DATOS</h2>
        </Col>
        <Col md="4" xl="3" xs="12">
          <FormGroup className="text-center">
            <label>
              <strong>Foto</strong>
            </label>
            <Label className="custom-file" style={{ height: "150px" }}>
              <img
                src={imgSrc}
                alt="Imagen"
                className="bg-light image-profile-form"
              />
              <input
                type="file"
                name="file-input"
                onChange={handleImage}
                style={{ display: "none" }}
              />
            </Label>
          </FormGroup>
        </Col>
        <Col md="8" xl="9" xs="12">
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>
                  Primer Nombre <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  name="name"
                  className={`form-control  form-control-dochoy ${
                    errors && errors.name && "is-invalid"
                  }`}
                  ref={register}
                  // defaultValue={person?.name}
                />
                {errors.name && renderError(errors.name.message)}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Segundo Nombre <span className="text-danger"></span>
                </Label>
                <input
                  type="text"
                  name="second_name"
                  className={`form-control  form-control-dochoy`}
                  ref={register}
                  // defaultValue={person?.name}
                />
                {/* {errors.name && renderError(errors.name.message)} */}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Primer apellido <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  name="lastname"
                  className={`form-control  form-control-dochoy`}
                  ref={register}
                  // defaultValue={person?.name}
                />
                {/* {errors.name && renderError(errors.name.message)} */}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Segundo apellido <span className="text-danger"></span>
                </Label>
                <input
                  type="text"
                  name="second_lastname"
                  className={`form-control  form-control-dochoy`}
                  ref={register}
                  // defaultValue={person?.name}
                />
                {/* {errors.name && renderError(errors.name.message)} */}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Username <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  name="username"
                  placeholder={person?.document}
                  readOnly
                  className={`form-control  form-control-dochoy ${
                    errors.username && "is-invalid"
                  }`}
                  ref={register}
                  // defaultValue={person?.document}
                  value={username}
                />
                {/* {errors.username && renderError(errors.username.message)} */}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>
                  <strong>Tipo Documento</strong>
                </label>
                <Controller
                  name="document_type"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <Select
                        options={documentTypes || []}
                        value={
                          documentTypes &&
                          documentTypes?.filter(
                            (option) => option.id === person?.document_type?.id
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        styles={{
                          control: (provided) =>
                            errors.document_type
                              ? {
                                  ...provided,
                                  borderColor: "red",
                                }
                              : provided,
                        }}
                      />
                    );
                  }}
                />
                {errors.document_type &&
                  renderError(errors.document_type.message)}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Número Documento <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  name="document"
                  readOnly
                  onChange={(e) => setUsername(e.target.value)}
                  className={`form-control  form-control-dochoy ${
                    errors.document && "is-invalid"
                  }`}
                  ref={register}
                  // defaultValue={person?.document}
                />
                {errors.document && renderError(errors.document.message)}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  Email <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  defaultValue={person?.email}
                  name="email"
                  placeholder="Email"
                  className={`form-control  form-control-dochoy ${
                    errors.email && "is-invalid"
                  }`}
                  ref={register}
                />
                {errors.email && renderError(errors.email.message)}
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        backdrop="static"
        keyboard={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={view === "Mobile" ? MobileStyles : customStyles}
        contentLabel="Example Modal"
      >
        <Button
          tooltip="Close"
          title="Close"
          className="btn-warning mb-4"
          onClick={closeModal}
        >
          <i className="fa fa-home mr-1 " style={{ fontSize: "large" }}></i>
          Close
        </Button>
        <AddressModal
          countries={countries}
          person={person}
          action={actionModal}
          info={infoEdit}
          closeModal={closeModal}
        />
      </Modal>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>
              Genero <span className="text-danger">*</span>
            </Label>
            <Controller
              name="gender"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={gender || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(gender) => {
                      onChange(gender);
                      setGenderSelected(gender);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.gender
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.gender && renderError(errors.gender.message)}
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              <strong>Fecha de Nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="birthdate"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Input
                    type="date"
                    name="birthDate"
                    className=" form-control-dochoy"
                    onChange={onChange}
                    defaultValue={value}
                    //value={value}
                  />
                );
              }}
            />
            {errors.birthdate && renderError(errors.birthdate.message)}
          </FormGroup>
        </Col>
        {/* <Col md="6">
          <FormGroup>
            <Label>
              <strong>Dirección Residencia</strong>
            </Label>
            <input
              type="text"
              name="address"
              placeholder="Direccion"
              className="form-control  form-control-dochoy"
              ref={register}
              defaultValue={person.address}
            />
          </FormGroup>
        </Col> */}

        <Col md="6">
          <FormGroup>
            <Label>
              <strong>Teléfono móvil</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="celular"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ onChange, value, ref }) => (
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country="co"
                  onChange={(phone) => onChange(phone)}
                  value={value}
                  inputRef={ref}
                />
              )}
            />
            {errors.celular && renderError(errors.celular.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row className="bg-light py-3">
        <Col md="4">
          <h6>
            <strong>Lugar de Nacimiento</strong>
          </h6>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Pais de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="country_birth"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione un Pais"
                    onChange={(pais) => {
                      console.log(pais);
                      setCountry(pais);
                      // eventOnSelectCountry(pais);
                      onChange(pais);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_country
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.country_birth && renderError(errors.country_birth.message)}
          </FormGroup>
        </Col>

        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Dpto de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="department_birth"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={states}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione un Departamento/Estado"
                    onChange={(state) => {
                      // eventOnSelectDepartment(state);
                      onChange(state);
                      setStateSelected(state);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_department
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.department_birth &&
              renderError(errors.department_birth.message)}
          </FormGroup>
        </Col>

        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Ciudad de nacimiento</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="city_birth"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione una ciudad"
                    value={value}
                    onChange={(city) => {
                      onChange(city);
                      setCitySelected(city);
                    }}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_city
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.city_birth && renderError(errors.city_birth.message)}
          </FormGroup>
        </Col>
      </Row>
      {/* <Row className="bg-light">
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Pais de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="country_resident"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione un Pais"
                    onChange={(pais) => {
                      setCountry(pais);
                      // eventOnSelectCountry(pais);
                      onChange(pais);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_country
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.country_resident &&
              renderError(errors.country_resident.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Dpto de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="department_resident"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={states}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione un Departamento/Estado"
                    onChange={(state) => {
                      // eventOnSelectDepartment(state);
                      onChange(state);
                      setStateSelected(state);
                    }}
                    value={value}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_department
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.department_resident &&
              renderError(errors.department_resident.message)}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Ciudad de residencia</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="city_resident"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione una ciudad"
                    value={value}
                    onChange={(city) => {
                      onChange(city);
                      setCitySelected(city);
                    }}
                    isClearable
                    styles={{
                      control: (provided) =>
                        errors.residence_city
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
            {errors.city_resident && renderError(errors.city_resident.message)}
          </FormGroup>
        </Col>
      </Row> */}

      <Row className="bg-light">
        <Col md="6">
          <h6>
            <strong>Lugar de Residencia</strong>
          </h6>
        </Col>
      </Row>
      <Row className="bg-light">
        <Col
          md="6"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="warning"
            color="warning"
            size="lg"
            onClick={() => openModal("crear")}
          >
            <i className="fa fa-plus"></i> Añadir una dirección
          </Button>
        </Col>
      </Row>
      <Row className="bg-light pb-3">
        <Col md="12">
          <Label>
            <strong>Dirección Residencia</strong>
            <span className="text-danger">*</span>
          </Label>
          {totalUserAddress &&
            totalUserAddress.length > 0 &&
            totalUserAddress.map((address, index) => {
              return (
                <>
                  <Row key={index}>
                    <Col md="8">
                      <Input
                        key={index}
                        className="form-control-dochoy"
                        defaultValue={address.full_address}
                      />
                    </Col>
                    <Col md="4">
                      <Button
                        style={{ height: "100%" }}
                        variant="primary"
                        color="primary"
                        onClick={() => openModal("editar", address)}
                      >
                        <i className="fa fa-edit"></i>
                      </Button>{" "}
                      <Button
                        style={{ height: "100%" }}
                        variant="danger"
                        color="danger"
                        onClick={() => deleteAddressUser(address.id)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </>
              );
            })}
        </Col>
      </Row>
      <Row className="pt-3">
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Escolaridad</strong>
            </Label>
            <Controller
              name="escolaridad"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={escolaridad || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    isClearable
                    value={value}
                    placeholder="Escolaridad"
                    styles={{
                      control: (provided) =>
                        errors.escolaridad
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Ocupación</strong>
            </Label>
            <Controller
              name="ocupacion"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={ocupacion || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    isClearable
                    value={value}
                    placeholder="Ocupacion"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>
              <strong>Estado Civil</strong>
            </label>
            <Controller
              name="civil_status"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={maritalStatus || []}
                    value={
                      maritalStatus &&
                      maritalStatus?.filter((option) => option.id == value)
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Grupo Sanguineo</Label>
            <Controller
              name="blood_type"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={bloodTypes || []}
                    value={
                      bloodTypes &&
                      bloodTypes?.filter((option) => option.id == value)
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              <strong>Grupo Étnico</strong>
            </Label>
            <Controller
              name="race"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={ethnicGroups || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={
                      ethnicGroups &&
                      ethnicGroups?.filter((option) => option.id == value)
                    }
                    placeholder="Grupo Étnico"
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>
              <strong>Convenio</strong>
            </label>
            <Controller
              name="agreement"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={agreements || []}
                    value={
                      agreements &&
                      agreements?.filter((option) => option.id == value)
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>
              <strong>Entidad</strong>
            </label>
            <Controller
              name="health_entity"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={healthEntities || []}
                    value={
                      healthEntities &&
                      healthEntities?.filter((option) => option.id == value)
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>
              <strong>Plan</strong>
            </label>
            <Controller
              name="plan"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={plans || []}
                    value={
                      plans && plans?.filter((option) => option.id == value)
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12 pt-2">
          <h4 className="text-left">
            <strong>Datos del Acompañante</strong>
          </h4>
        </Col>
      </Row>
      <Row className="bg-default">
        <Col md="4">
          <FormGroup>
            <label>Nombre Acompañante</label>
            <input
              type="text"
              name="accompanying_name"
              className="form-control form-control-dochoy"
              ref={register}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>Documento Acompañante</label>
            <input
              type="text"
              name="accompanying_document"
              className="form-control form-control-dochoy"
              ref={register}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>Parentesco</label>
            <Controller
              name="parentesco"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={parentesco || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={onChange}
                    isClearable
                    value={value}
                    styles={{
                      control: (provided) =>
                        errors.parentesco
                          ? {
                              ...provided,
                              borderColor: "red",
                            }
                          : provided,
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="bg-default">
        <Col md="6">
          <FormGroup>
            <label>Teléfono Acompañante</label>
            <input
              type="text"
              name="telefono_acompanante"
              className="form-control form-control-dochoy"
              ref={register}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label>Celular Acompañante</label>
            <Controller
              name="celular_acompanante"
              control={control}
              className="form-control-dochoy"
              rules={{ required: true }}
              render={({ onChange, value, ref }) => (
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  placeholder="Celular"
                  country="co"
                  className="form-control-dochoy"
                  onChange={(phone) => onChange(phone)}
                  value={value}
                  inputRef={ref}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row col="12" xl>
        <Col className="text-left w-25" col="12">
          <Button type="submit" color="primary">
            {loading ? (
              <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24" />
            ) : (
              "Actualizar mis datos"
            )}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

Form.propTypes = {
  state: PropTypes.shape({
    person: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    departmentBirth: PropTypes.array,
    cityBirth: PropTypes.array,
    departmentResident: PropTypes.array,
    cityResident: PropTypes.array,
  }),
  props: PropTypes.shape({
    gender: PropTypes.array,
    maritalStatus: PropTypes.array,
    countries: PropTypes.array,
    parentesco: PropTypes.array,
    ocupacion: PropTypes.array,
    ethnicGroups: PropTypes.array,
    escolaridad: PropTypes.array,
    documentTypes: PropTypes.array,
    bloodTypes: PropTypes.array,
  }),
  submitForm: PropTypes.func,
  onSelectCountry: PropTypes.func,
  onSelectDepartment: PropTypes.func,
  onSelectCountryBirth: PropTypes.func,
  onSelectDepartmentBirth: PropTypes.func,
  onSelectCountryResident: PropTypes.func,
  onSelectDepartmentResident: PropTypes.func,
  fileChangedHandler: PropTypes.func,
  loading: PropTypes.bool,
  gender: PropTypes.string,
  ethnicGroups: PropTypes.string,
  bloodTypes: PropTypes.string,
  countries: PropTypes.string,
  maritalStatus: PropTypes.string,
  parentesco: PropTypes.string,
  ocupacion: PropTypes.string,
  escolaridad: PropTypes.string,
};

export default Form;
