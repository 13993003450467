import React from "react";

// Libraries
import { each, map } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Material Comps
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import withWidth from "@material-ui/core/withWidth";

// Material icons
import ReplyIcon from '@material-ui/icons/Reply';
import CloseIcon from "@material-ui/icons/Close";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";

// User Actions
import {
  getUserInfo,
  markAllAsView,
  markNotificationAsView,
  markNotificationAsViewLaravel,
  setLastActionUser,
  setNotificationTabValue,
  setSearchTerm,
  getUserNotification,
} from "../../redux/actions/userAction";
import { filterNotifications } from "../../Selectors/userSelector";

// Constants
import { userInfo } from "../../redux/actions/types";

// Styles
import styles from "./Styles";

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
    };

    this.onPressBell = this.onPressBell.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onMarkAsView = this.onMarkAsView.bind(this);
    this.goOferts = this.goOferts.bind(this);
    this.markAllAsView = this.markAllAsView.bind(this);
  }

  /* TODO:: Se comenta ya que se llama dos veces en varias pantallas
  componentWillMount() {
    this.props.getUserInfo(userInfo, true);
  } 
  */

  componentDidUpdate() {
    if (this.props.user.lastAction === "getNotificationsSuccess") {
      this.props.setLastActionUser();
    } else if (this.props.user.lastAction === "notificationStatusUpdated") {
      this.props.setLastActionUser();
    }
  }

  // region Functions
  someNotificationIsNew() {
    let foundNewNotification = false;
    each(this.props.user.notifications, (notification) => {
      if (notification.status === "0") {
        foundNewNotification = true;
        return false;
      }
    });

    return foundNewNotification;
  }

  // Region Actions
  onPressBell() {
    this.setState({ showNotificationModal: !this.state.showNotificationModal });
  }

  handleTabChange(event, value) {
    this.props.setNotificationTabValue(value);
  }

  onChangeSearch(event) {
    const val = event.target.value;
    this.props.setSearchTerm(val);
  }

  onMarkAsView(notification) {
    this.props
      .markNotificationAsViewLaravel(notification.id)
      .then((res) =>
        this.props.getUserNotification(this.props.user?.userInfoApi?.id)
      )
      .then((res) => console.log("RESPONSE", res));
  }
  goOferts(notification, type = "medico") {
    if (!notification.executingRequest && type === "medico") {
      this.context.router.history.push(
        "/CitasMedico/MisCitas?tab=consultasSinOfertas"
      );
      this.setState({ showNotificationModal: false });
    }
    if (!notification.executingRequest && type === "patient") {
      this.context.router.history.push("/CitaEspera/Seleccion");
      this.setState({ showNotificationModal: false });
    }
  }

  markAllAsView() {
    this.props.markAllAsView();
  }

  // #region Build Functions
  buildNotificationModal() {
    return (
      <Dialog
        onClose={this.onPressBell}
        open={this.state.showNotificationModal}
        maxWidth={"lg"}
        PaperProps={{
          style: {
            overflowY: "visible",
            width: this.props.width === "xs" ? "100%" : "60%",
            background: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {/* <DialogTitle>{this.renderModalTitle()}</DialogTitle> */}
        <DialogTitle></DialogTitle>
        <DialogContent
          dividers
          className={{ overflowY: "visible" }}
          style={{ padding: "0" }}
        >
          {/* <div style={styles.searchContainer}>
            <div style={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              style={styles.inputInput}
              onChange={this.onChangeSearch}
            />
          </div> */}
          {/* {this.buildTabs()} */}
          {this.buildNotificationTable()}
        </DialogContent>
        {/* <DialogActions>{this.renderActionButtons()}</DialogActions> */}
      </Dialog>
    );
  }

  renderModalTitle() {
    return (
      <Grid
        container
        direction={"row"}
        justify={"space-between"}
        alignItems={"center"}
      >
        <span>{"Notificaciones"}</span>
        <IconButton aria-label="Cerrar modal" onClick={this.onPressBell}>
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  }

  buildTabs() {
    return (
      <Paper square>
        <Tabs
          value={this.props.user.tabValue}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            icon={<NotificationImportant />}
            label={`Nuevas Notificaciones`}
          />
          {/* <Tab
            icon={<NotificationImportantOutlined />}
            label={`Notificaciones Previas`}
          /> */}
        </Tabs>
      </Paper>
    );
  }

  buildNotificationTable() {
    const filteredNotification = this.props.filteredNotification;
    // console.log(filteredNotification);
    if (!filteredNotification.length) {
      return (
        <label
          style={styles.noInfo}
        >{`No hay notificaciones para mostrar ...`}</label>
      );
    }

    return map(filteredNotification, (notification, index) => {
      return (
        <div style={styles.notificationRow} key={index}>
          <div style={styles.notificationRowContainer}>
            <div style={styles.notificationRowContainerBlock85}>
              <div style={styles.notificationRowContainer}>
                <i
                  className="fa fa-calendar-o mr-1"
                  aria-hidden="true"
                  style={{ color: "#4e7fa5", paddingTop: "2px" }}
                ></i>
                <span
                  className="span-warning"
                  style={styles.titleDate}
                >{`Fecha:`}</span>
                <span style={styles.titleText}>{notification.dateN}</span>
              </div>
              <div style={styles.notificationRowContainer}>
                <i
                  className="fa fa-clock-o mr-1"
                  aria-hidden="true"
                  style={{ color: "#4e7fa5", paddingTop: "2px" }}
                ></i>
                <span
                  className="span-warning"
                  style={styles.titleHour}
                >{`Hora:`}</span>
                <span style={styles.titleText}>{notification.dateH}</span>
              </div>
              <div style={styles.notificationRowContainer}>
                <i
                  className="fa fa-envelope-o mr-1"
                  aria-hidden="true"
                  style={{ color: "#4e7fa5", paddingTop: "2px" }}
                ></i>
                <span
                  className="span-warning"
                  style={styles.title}
                >{`Mensaje:`}</span>
                <span style={styles.titleText}>
                  {notification?.data?.message}
                </span>
              </div>
              <div style={styles.notificationRowContainer}>
                <i
                  className="fa fa-user-o mr-1"
                  aria-hidden="true"
                  style={{ color: "#4e7fa5", paddingTop: "2px" }}
                ></i>
                <span
                  className="span-warning"
                  style={styles.titleSender}
                >{`Enviado Por:`}</span>
                <span style={styles.titleText}>
                  {notification?.data?.notified_by}
                </span>
              </div>
            </div>
            {notification?.data && (
              <>
                <div style={styles.notificationRowContainerBlock15}>
                  {notification?.data?.notification_type &&
                    notification?.data?.notification_type === 3 && (
                      <Tooltip
                        title={
                          notification.executingRequest
                            ? "Espera mientras se procesa la solicitud"
                            : "Ir a consultas sin ofertas"
                        }
                      >
                        <IconButton
                          focusRipple={false}
                          disableFocusRipple
                          disableRipple
                          onClick={() => {
                            this.goOferts(notification);
                          }}
                        >
                          <ReplyIcon style={{ color: "#014478" }}/>
                        </IconButton>
                      </Tooltip>
                    )}
                  {notification?.data?.notification_type &&
                    notification?.data?.notification_type === 4 && (
                      <Tooltip
                        title={
                          notification.executingRequest
                            ? "Espera mientras se procesa la solicitud"
                            : "Ver oferta"
                        }
                      >
                        <IconButton
                          focusRipple={false}
                          disableFocusRipple
                          disableRipple
                          onClick={() => {
                            this.goOferts(notification, "patient");
                          }}
                        >
                          <ReplyIcon style={{ color: "#014478" }} />
                        </IconButton>
                      </Tooltip>
                    )}

                  {notification?.executingRequest && (
                    <div
                      data-loader="circle-side"
                      style={styles.overrideSpinner}
                    />
                  )}
                  <div>
                    <Tooltip
                      title={
                        notification.executingRequest
                          ? "Espera mientras se procesa la solicitud"
                          : "Marcar como leido"
                      }
                    >
                      <IconButton
                        focusRipple={false}
                        disableFocusRipple
                        disableRipple
                        onClick={() => {
                          this.onMarkAsView(notification);
                        }}
                      >
                        <DoneAllIcon style={{ color: "#ff6a06" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  }

  renderActionButtons() {
    return (
      <div>
        <Button onClick={this.onPressBell} color="primary">
          {"Cerrar"}
        </Button>
        <Button
          onClick={this.markAllAsView}
          variant="contained"
          color="primary"
        >
          {this.props.user.executingRequest ? (
            <div data-loader="circle-side" style={styles.overrideSpinner} />
          ) : (
            "Marcar todos como vistos"
          )}
        </Button>
      </div>
    );
  }

  render() {
    const filteredNotification = this.props.filteredNotification;
    return (
      <div
        style={
          filteredNotification?.length > 0
            ? styles.notificationContainerFill
            : styles.notificationContainer
        }
        id={"notifiContainer"}
      >
        <div onClick={this.onPressBell}>
          <i
            style={styles.bellSize}
            className="fa fa-bell-o"
            aria-hidden="true"
          ></i>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {filteredNotification?.length > 0
              ? filteredNotification?.length
              : null}
          </span>
        </div>
        {this.buildNotificationModal()}
      </div>
    );
  }
}

Notifications.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  user: PropTypes.object,
  filteredNotification: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  setLastActionUser: PropTypes.func,
  markNotificationAsView: PropTypes.func,
  markNotificationAsViewLaravel: PropTypes.func,
  markAllAsView: PropTypes.func,
  getUserNotification: PropTypes.func,
  setNotificationTabValue: PropTypes.func,
  setSearchTerm: PropTypes.func,
  width: "xs" | "sm" | "md" | "lg" | "xl",
};

const mapStateToProps = (state) => ({
  user: state.user,
  filteredNotification: filterNotifications(state.user),
});
Notifications.contextTypes = {
  router: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, {
  getUserInfo,
  markAllAsView,
  markNotificationAsView,
  markNotificationAsViewLaravel,
  setLastActionUser,
  setNotificationTabValue,
  setSearchTerm,
  getUserNotification,
})(withWidth()(Notifications));
